import React, { useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import CardHeader from 'components/common/CardHeader';
import IconButton from 'components/common/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { employeeTableColumns } from './partials/EmployeeTableColumns';
import { Link } from 'react-router-dom';
import { fetchAcademicLevels } from 'redux/features/common/actions';

const Employees = () => {
  const dispatch = useDispatch();

  const { data: employees } = useSelector(state => state.employees);

  useEffect(() => {
    dispatch(fetchAcademicLevels());
  }, [dispatch]);

  return (
    <div>
      <Card>
        <CardHeader
          title="Employees"
          icon="user-tie"
          titleTag="h4"
          className="bg-light"
          endEl={
            <Link to="/employees/add">
              <IconButton
                variant="falcon-primary"
                size="sm"
                iconAlign="right"
                icon="plus"
                iconClassName="ms-1"
              >
                Add Employee
              </IconButton>
            </Link>
          }
        />{' '}
        <Card.Body>
          <AdvanceTableWrapper
            columns={employeeTableColumns}
            data={employees}
            pagination
            perPage={10}
          >
            <Row className="flex-end-center mb-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={employees.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Employees;
