import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/CardHeader';
import SutdentIcon from 'assets/img/icons/social.svg';
import Flex from 'components/common/Flex';

const Student = ({ data: { title, studentCount }, ...rest }) => (
  <Card {...rest} className="h-100">
    <FalconCardHeader
      title="Students"
      light={false}
      titleTag="h5"
      className="pb-3"
    />
    <Card.Body className="pt-2">
      <Row className="g-0 h-100 align-items-center">
        <Col as={Flex} alignItems="center">
          <img className="me-3" src={SutdentIcon} alt="" height="60" />
          <div>
            <h6 className="mb-2">{title}</h6>
            <div className="fs--2 fw-semi-bold"></div>
          </div>
        </Col>
        <Col xs="auto" className="text-center ps-2">
          <div className="fs-4 fw-normal font-sans-serif text-primary mb-1 lh-1">
            {studentCount}
          </div>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

Student.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default Student;
