import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Table, Form, Button } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showCampus, updateGrade } from 'redux/features/campus/actions';
import { useSelector } from 'react-redux';
import { STATUSES } from 'common/constants';
import Loader from 'components/common/Loader';
import { toast } from 'react-toastify';
import { CampusGrade } from './CampusGrades';

const ShowCampus = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { singleCampus: campus, status } = useSelector(state => state.campuses);
  const [IsEdit, setIsEdit] = useState(false);
  const [grades, setGrades] = useState(campus.seats);

  useEffect(() => {
    dispatch(showCampus(id));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(campus).length > 0) {
      let newCampus = [...campus.seats];
      let obj = {};
      for (var i = 0; i < campus.seats.length; i++) {
        if (obj[campus.seats[i].level.name]) {
          obj[campus.seats[i].level.name] += 1;
        } else {
          obj[campus.seats[i].level.name] = 1;
        }
      }
      newCampus = newCampus.map(e => {
        if (e.level.name in obj) {
          return {
            ...e,
            count: obj[e.level.name]
          };
        }
      });
      setGrades([...newCampus.sort((a, b) => a.id - b.id)]);
    } else setGrades([]);
    // setGrades(campus.seats);
  }, [campus]);

  // Hanlde Change
  const handleChange = (value, name, idx) => {
    const newArr = grades.map((obj, index) => {
      if (index === idx) {
        return {
          ...obj,
          [name]: value
        };
      }
      return obj;
    });

    setGrades(newArr);
  };

  // Handle submit
  const handleSubmit = () => {
    dispatch(
      updateGrade({
        id,
        grades: grades.map(el => {
          return { ...el, level: el.level.id };
        })
      })
    );
    toast.success('Campus seats have been updated');
    setIsEdit(false);
  };

  return status === STATUSES.LOADING ? (
    <Card>
      <Loader />
    </Card>
  ) : (
    <>
      <PageHeader title={campus.name} titleTag="h3" className="mb-3">
        <p className="fs--1 mt-3">
          <strong>Unique ID:</strong> {campus.campusId}
        </p>
        <p className="fs--1">
          <strong>Address:</strong> {campus.address} Quetta
        </p>
        <p className="fs--1">
          <strong>Academic Levels: </strong>
          {campus.academicLevels &&
            campus.academicLevels.map((level, idx) => (
              <span
                key={idx}
                className="badge border link-secondary text-decoration-none me-1"
              >
                {level.name}
              </span>
            ))}
        </p>
        <div className="fs--1">
          <strong>Status: </strong>
          {campus.status === 'active' ? (
            <SoftBadge pill bg="success" className="fs--2">
              Active
              <FontAwesomeIcon
                icon="check"
                className="ms-1"
                transform="shrink-2"
              />
            </SoftBadge>
          ) : (
            <SoftBadge pill bg="warning" className="fs--2">
              Inactive
              <FontAwesomeIcon
                icon="check"
                className="ms-1"
                transform="shrink-2"
              />
            </SoftBadge>
          )}
        </div>
      </PageHeader>
      <Card className="mb-3">
        <Card.Body>
          <Row>
            <Col md={12} lg={12} className="mb-4 mb-lg-0">
              <h5 className="mb-3 fs-0">Seats</h5>
            </Col>
            <Col md={12} lg={12} className="mb-4 mb-lg-0">
              <Form>
                <Table bordered size="sm" responsive className="text-center ">
                  <thead className="bg-light">
                    <tr>
                      <th>Grade</th>
                      <th>Equivalent</th>
                      <th width={'150px'}>Allocated Seats </th>
                      <th width={'200px'}>Gender Distribution</th>
                      <th width={'150px'}>Girls</th>
                      <th width={'150px'}>Boys</th>
                    </tr>
                  </thead>
                  <tbody>
                    {grades &&
                      grades.map((seat, idx) => (
                        <CampusGrade
                          idx={idx}
                          key={idx}
                          data={seat}
                          handleChange={handleChange}
                          IsEdit={IsEdit}
                        />
                      ))}
                  </tbody>
                </Table>
                {IsEdit ? (
                  <Button
                    className="float-right"
                    variant="success"
                    onClick={handleSubmit}
                  >
                    SAVE
                  </Button>
                ) : (
                  <Button
                    className="float-right"
                    onClick={() => setIsEdit(true)}
                  >
                    EDIT
                  </Button>
                )}
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ShowCampus;
