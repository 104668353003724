import React, { useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { useDispatch } from 'react-redux';
import { guardianFilter } from 'redux/features/guardians/actions';
import { Col, Row, Spinner } from 'react-bootstrap';
import { relationWithGuardian } from '../Enums';

const GuardianForm = ({ register, errors }) => {
  const dispatch = useDispatch();

  const [guardianExists, setGuardianExists] = useState(true);
  const [guardianData, setGuardianData] = useState({});
  const [cnicLoader, setCnicLoader] = useState(false);

  const handleChange = e => {
    let cnic = e.target.value;
    if (cnic) {
      setCnicLoader(true);
      dispatch(guardianFilter({ cnic: cnic }))
        .unwrap()
        .then(payload => {
          setCnicLoader(false);

          setGuardianExists(true);
          setGuardianData(payload);
        })
        .catch(() => {
          setGuardianExists(false);
          setGuardianData([]);
          setCnicLoader(false);
        });
    } else {
      setGuardianExists(true);
      setCnicLoader(false);
    }
  };

  return (
    <>
      <h1 className="text-center mb-5">1. Select Guardian</h1>

      {!guardianExists ? (
        <p className="text-center">
          <small className="text-danger">
            No record exists for this CNIC number. <br />
            Enter a different CNIC number, or go back to Guardian section and
            add this guaridan in the records.
          </small>
        </p>
      ) : (
        <></>
      )}
      <WizardInput
        label="Guardain CNIC"
        type="text"
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('guaridanID', {
            required: 'Guaridan ID is requiredsdfasdf'
          })
        }}
        placeholder="Enter Guardian CNIC"
        onBlur={handleChange}
      />
      <Row>
        <Col xs={4}></Col>
        <Col>
          {cnicLoader ? <Spinner animation="border" variant="secondary" /> : ''}
        </Col>
      </Row>

      {Object.keys(guardianData).length > 0 ? (
        <Row>
          <Col md="4"></Col>
          <Col md="6">
            <div className="mb-5">
              <h5 className="text-success">Guardian Exists</h5>
              <strong>Full Name:</strong> {guardianData.title}{' '}
              {guardianData.name} <br />
              <strong>CNIC: </strong>
              {guardianData.cnic} <br />
              <strong>Email: </strong> {guardianData.email} <br />
              <strong>Guardian ID: </strong> {guardianData.id} <br />
              <strong>Primary Number: </strong> {guardianData.primaryNumber}
            </div>
          </Col>
        </Row>
      ) : (
        ''
      )}

      {guardianExists ? (
        <WizardInput
          label="Relation with Guardian"
          type="select"
          name="relation_with_guardian"
          errors={errors}
          placeholder="--- Please relation ---"
          options={relationWithGuardian}
          formGroupProps={{ className: 'mb-3' }}
          formControlProps={{
            ...register('relation_with_guardian', {
              required: 'Guaridan relation is required'
            })
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

GuardianForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object
};

export default GuardianForm;
