import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function ControlledInput({
  value,
  onChange,
  name,
  index,
  type,
  disabled,
  max
}) {
  const [innerValue, setInnerValue] = useState(value);
  useEffect(() => {
    setInnerValue(value);
  }, [value, onChange]);
  return (
    <input
      type={type}
      className="w-50 small-input text-center"
      size="sm"
      value={innerValue}
      checked={type === 'checkbox' && innerValue ? 'checked' : null}
      name={name}
      onChange={e => {
        type === 'number'
          ? setInnerValue(parseInt(e.target.value))
          : type === 'checkbox'
          ? setInnerValue(e.target.checked)
          : setInnerValue(e.target.value > max ? max : e.target.value);
        type === 'checkbox' && onChange(e.target.checked, name, index);
      }}
      onBlur={e => {
        type !== 'checkbox' && type === 'number'
          ? parseInt(e.target.value) > parseInt(max)
            ? onChange(parseInt(max), name, index)
            : onChange(parseInt(e.target.value), name, index)
          : onChange(innerValue, name, index);
      }}
      style={{ textAlign: 'right' }}
      disabled={disabled}
      max={max}
      min={0}
    />
  );
}

ControlledInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  max: PropTypes.max
};

export default ControlledInput;
