import Header from 'components/common/Header';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { showStudent, updateStudent } from 'redux/features/student/actions';
import { STATUSES } from 'common/constants';
import Loader from 'components/common/Loader';
import EditStudentInfoForm from './partials/EditStudentInfoForm';
import EditEnrollmentForm from './partials/EditEnrollmentForm';
import { toast } from 'react-toastify';

const EditStudent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  // Edit Student
  const { singleStudent, status } = useSelector(state => state.student);
  const [studentForm, setStudentForm] = useState(singleStudent);

  useEffect(() => {
    dispatch(showStudent(id));
  }, []);

  useEffect(() => {
    setStudentForm(singleStudent);
  }, [singleStudent, status]);
  // Handle Form
  const handleChange = e => {
    console.log(e.target);
    setStudentForm({ ...studentForm, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('campus', studentForm.campus.id);
    formData.append('session', studentForm.session);
    formData.append('grade', studentForm.grade.id);
    formData.append(
      'date_of_enrolment',
      new Date(studentForm.date_of_enrolment).toISOString()
    );
    formData.append(
      'relation_with_guardian',
      studentForm.relation_with_guardian
    );

    console.log('STUDENT DATA => ', typeof studentForm.photoPath[0]);

    if (singleStudent.photoPath !== studentForm.photoPath) {
      formData.append('photoPath', studentForm.photoPath[0]);
    }
    formData.append('fullname', studentForm.fullname);
    formData.append('gender', studentForm.gender);
    formData.append('fullname_urdu', studentForm.fullname_urdu);
    formData.append('father_name', studentForm.father_name);
    formData.append('father_cnic', studentForm.father_cnic);
    formData.append('mother_name', studentForm.mother_name);
    formData.append(
      'dateof_birth',
      new Date(studentForm.dateof_birth).toISOString()
    );
    formData.append('domicile', studentForm.domicile);

    dispatch(updateStudent({ id, formData }))
      .unwrap()
      .then(() => {
        toast.success('Student has been updated');
        navigate('/students');
      })
      .catch(e => {
        toast.error(`Failed to update student ${e.error}`);
      });
  };
  return status === STATUSES.LOADING || !studentForm ? (
    <Card>
      <Loader />
    </Card>
  ) : (
    <div>
      <Header
        title="Update Student"
        icon="plus"
        btnLink="/"
        btnTitle="Go Back"
      ></Header>
      <Card className="mb-3">
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit} autoComplete="off">
            <>
              <Row>
                <Col xs={1} />
                <Col xs={6}>
                  <EditEnrollmentForm
                    studentForm={studentForm}
                    setStudentForm={setStudentForm}
                    singleStudent={singleStudent}
                    handleChange={handleChange}
                  />
                  <EditStudentInfoForm
                    studentForm={studentForm}
                    setStudentForm={setStudentForm}
                    singleStudent={singleStudent}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs={3}></Col>
                <Col xs={4}>
                  <Button variant="success" type="submit">
                    Update
                  </Button>
                </Col>
              </Row>
            </>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EditStudent;
