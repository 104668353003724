import React from 'react';
import { Badge, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import img from '../../../assets/img/logos/avatar.png';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import {
  deleteGuardian,
  updateGurdian
} from 'redux/features/guardians/actions';

const GuardianActions = ({ data }) => {
  const dispatch = useDispatch();

  // Delete
  const handleDelete = id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteGuardian(id))
          .unwrap()
          .then(() =>
            Swal.fire('Deleted!', 'Guardian has been deleted', 'success')
          )
          .catch(error =>
            Swal.fire(
              'Error!',
              `Internal Server error ${error.message}`,
              'error'
            )
          );
      }
    });
  };

  // Suspand
  const toggleSuspend = (id, suspend) => {
    const formData = new FormData();

    formData.append('suspend', suspend);
    Swal.fire({
      title: 'Are you sure?',
      text: `You want to ${suspend ? 'Suspend' : 'Active'} Guardian`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${suspend ? 'Suspend' : 'Active'} it!`
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(updateGurdian({ id, formData }))
          .unwrap()
          .then(() =>
            Swal.fire(
              'Updated!',
              `Guardian has been ${suspend ? 'Suspended' : 'Activated'}`,
              'success'
            )
          )
          .catch(error =>
            Swal.fire(
              'Error!',
              `Internal Server error ${error.message}`,
              'error'
            )
          );
      }
    });
  };

  return (
    <CardDropdown iconClassName="fs--1">
      <div className="py-2">
        <Dropdown.Item as={Link} to={`/guardians/edit/${data.id}`}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleDelete(data.id)}>
          Delete
        </Dropdown.Item>
        {data.suspend ? (
          <Dropdown.Item onClick={() => toggleSuspend(data.id, false)}>
            Active
          </Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={() => toggleSuspend(data.id, true)}>
            Suspend
          </Dropdown.Item>
        )}
      </div>
    </CardDropdown>
  );
};

GuardianActions.propTypes = {
  data: PropTypes.object
};

export const gurdianTableColumns = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, photo, guardianId, id, title } = rowData.row.original;
      return (
        <Link to={`/guardians/${id}`} style={{ textDecoration: 'none' }}>
          <Flex alignItems="center">
            {photo === 'placeholder.jpg' ? (
              <Avatar src={img} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={name} className="me-2" />
            )}
            <div className="flex-1">
              <h5 className="mb-0 fs--1">
                {title} {name}
              </h5>
              <span className="text-muted">{guardianId}</span>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'primaryNumber',
    Header: 'Primary Number',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { email, primaryNumber, secondaryNumber } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            <div>
              <strong>Email:</strong> {email}
            </div>
            <div>
              <strong>Cell:</strong> {primaryNumber} Primary)
            </div>
            <div>
              <strong>Cell:</strong> {secondaryNumber}
            </div>
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'suspend',
    Header: 'Status',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { suspend } = rowData.row.original;
      return (
        <Flex alignItems="center">
          <div className="flex-1">
            {suspend ? (
              <Badge bg="warning">Suspend</Badge>
            ) : (
              <Badge bg="primary">Active</Badge>
            )}
          </div>
        </Flex>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => <GuardianActions data={rowData.row.original} />
  }
];
