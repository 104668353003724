import { createSlice } from '@reduxjs/toolkit';
import { STATUSES } from 'common/constants';
import {
  addGuardian,
  deleteGuardian,
  fetchGuardians,
  guardianFilter,
  showGuardian,
  updateGurdian
} from './actions';

export const guardianSlice = createSlice({
  name: 'guardian',
  initialState: {
    data: [],
    errors: [],
    status: STATUSES.LOADING,
    singleGuardian: {}
  },
  extraReducers(builder) {
    builder;
    // Add Gurdian
    builder.addCase(addGuardian.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.status = STATUSES.IDLE;
      state.errors = [];
    });
    builder.addCase(addGuardian.rejected, (state, action) => {
      state.status = STATUSES.ERROR;
      state.errors = action.payload.message;
    });
    builder.addCase(addGuardian.pending, state => {
      state.status = STATUSES.LOADING;
      state.errors = [];
    });

    // Filter Guadian
    builder.addCase(guardianFilter.fulfilled, state => {
      state.status = STATUSES.IDLE;
    });
    builder.addCase(guardianFilter.pending, state => {
      state.status = STATUSES.LOADING;
    });
    builder.addCase(guardianFilter.rejected, (state, action) => {
      state.status = STATUSES.IDLE;
      state.error = action.payload;
    });

    // Fetch Guardian
    builder.addCase(fetchGuardians.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(fetchGuardians.pending, state => {
      state.status = STATUSES.LOADING;
    });
    builder.addCase(fetchGuardians.rejected, (state, action) => {
      state.status = STATUSES.IDLE;
      state.error = action.payload;
    });

    // Delete Guardain
    builder.addCase(deleteGuardian.fulfilled, (state, action) => {
      state.status = STATUSES.IDLE;
      state.data = state.data.filter(data => data.id !== action.payload);
    });
    builder.addCase(deleteGuardian.pending, () => {});
    builder.addCase(deleteGuardian.rejected, (state, action) => {
      state.status = STATUSES.IDLE;
      state.error = action.payload;
    });

    // Update Guardain
    builder.addCase(updateGurdian.fulfilled, (state, action) => {
      state.status = STATUSES.IDLE;
      state.data = state.data.map(el =>
        el.id === action.payload.id ? action.payload : el
      );
    });
    builder.addCase(updateGurdian.pending, state => {
      state.status = STATUSES.LOADING;
    });
    builder.addCase(updateGurdian.rejected, (state, action) => {
      state.status = STATUSES.IDLE;
      state.error = action.payload;
    });

    // Show Guardain
    builder.addCase(showGuardian.fulfilled, (state, action) => {
      state.status = STATUSES.IDLE;
      state.singleGuardian = action.payload;
    });
    builder.addCase(showGuardian.pending, state => {
      state.status = STATUSES.LOADING;
    });
    builder.addCase(showGuardian.rejected, (state, action) => {
      state.status = STATUSES.IDLE;
      state.error = action.payload;
    });
  }
});

export default guardianSlice.reducer;
