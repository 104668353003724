import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Card, Col, Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Header = ({ title, icon, btnTitle, btnLink }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">
              {' '}
              <FontAwesomeIcon icon={icon} /> {title}
            </h5>
          </Col>
          <Col xs="auto">
            <Link to={btnLink}>
              <Button size="sm" variant="falcon-primary">
                {btnTitle}
              </Button>
            </Link>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  btnTitle: PropTypes.string,
  btnLink: PropTypes.string
};

export default Header;
