import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'common/constants';

export const fetchCampuses = createAsyncThunk('campuses/fetch', async () => {
  const response = await axios.get(`${BASE_URL}/campuses`);
  return response.data;
});

export const createCampus = createAsyncThunk(
  'campus/create',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/campuses`, data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      return rejectWithValue(err.response.data);
    }
  }
);

export const showCampus = createAsyncThunk('campus/show', async id => {
  const response = await axios.get(`${BASE_URL}/campuses/${id}`);
  return response.data;
});

export const deleteCampus = createAsyncThunk('campus/delete', async id => {
  const response = await axios.delete(`${BASE_URL}/campuses/${id}`);
  return response.data;
});

export const updateCampus = createAsyncThunk(
  'campus/update',
  async ({ id, data }) => {
    console.log('Slice Data', data);
    const response = await axios.patch(`${BASE_URL}/campuses/${id}`, data);
    return response.data;
  }
);

export const updateGrade = createAsyncThunk(
  'campus/updateGrade',
  async ({ id, grades }) => {
    const response = await axios.post(`${BASE_URL}/grades/${id}`, { grades });
    return response.data;
  }
);

export const updateSignature = createAsyncThunk(
  'updateSignature/update',
  async ({ data }) => {
    const response = await axios.post(`${BASE_URL}/campuses/upload`, data);
    return response.data;
  }
);
