import AppContext from 'context/Context';
import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout.js';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import Dashboard from 'components/dashboard';
import AuthLayout from 'layouts/AuthLayout';
import Login from 'pages/auth/Login';

import Employees from 'pages/employee/Employees';
import AddEmployee from 'pages/employee/AddEmployee.js';

import Info from 'pages/basicInfo/Info.js';
import FeeStructure from 'pages/feeStructure/FeeStructure.js';

import ViewCampuses from 'pages/campuses/ViewCampuses.js';
import ShowCampus from 'pages/campuses/ShowCampus.js';
import AddGuardian from 'pages/guardians/AddGuardian.js';
import ViewGuardians from 'pages/guardians/ViewGuradians.js';
import EditGuardian from 'pages/guardians/EditGuardian.js';
import ShowGuardian from 'pages/guardians/ShowGuardian.js';
import Students from 'pages/students/Students.js';
import AddStudent from 'pages/students/AddStudent.js';
import ShowStudent from 'pages/students/ShowStudent.js';
import EditStudent from 'pages/students/EditStudent.js';

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>
        {/*- ------------- Authentication ---------------------------  */}

        {/* //--- MainLayout Starts  */}
        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route path="/" element={<Dashboard />} />

          {/*Basic Info*/}
          <Route path="/basic_info" element={<Info />} />

          {/*Students*/}
          <Route path="/students" element={<Students />} />
          <Route path="/students/add" element={<AddStudent />} />
          <Route path="/students/:id" element={<ShowStudent />} />
          <Route path="/students/edit/:id" element={<EditStudent />} />

          {/*Guardians*/}
          <Route path="/guardians/add" element={<AddGuardian />} />
          <Route path="/guardians" element={<ViewGuardians />} />
          <Route path="/guardians/edit/:id" element={<EditGuardian />} />
          <Route path="/guardians/:id" element={<ShowGuardian />} />

          {/*Employee*/}
          <Route path="/employees" element={<Employees />} />
          <Route path="/employees/add" element={<AddEmployee />} />

          {/*Finance*/}
          <Route path="/finance/fee-structure" element={<FeeStructure />} />

          {/*Campuses*/}
          <Route path="/campuses" element={<ViewCampuses />} />
          <Route path="/campuses/:id" element={<ShowCampus />} />
        </Route>

        {/* //--- MainLayout end  */}

        {/* <Navigate to="/errors/404" /> */}
        <Route path="*" element={<Navigate to="/errors/404" replace />} />
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
