import logo from 'assets/img/icons/chrome-logo.png';
export const quickLinks = [
  {
    avatar: logo,
    title: 'St. LMS',
    link: `https://www.lms.tfs.school`
  },
  {
    avatar: logo,
    title: 'Tr. LMS',
    link: `https://www.lms.tfs.school`
  },
  {
    avatar: logo,
    title: 'Ast. LMS',
    link: `https://www.lms.tfs.school`
  },
  {
    avatar: logo,
    title: 'TFS Site',
    link: `https://www.tfs.school`
  }
];
