import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import CardHeader from 'components/common/CardHeader';
import IconButton from 'components/common/IconButton';
import { Link } from 'react-router-dom';
import { studentsTableColumns } from './partials/StudentTableColumns';
import { shallowEqual, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchStudents } from 'redux/features/student/actions';
import Loader from 'components/common/Loader';
import { STATUSES } from 'common/constants';

const Students = () => {
  const dispatch = useDispatch();

  const data = useSelector(state => state.student.data, shallowEqual);
  const status = useSelector(state => state.student.status, shallowEqual);

  const [students, setStudents] = useState(data);

  useEffect(() => {
    dispatch(fetchStudents());
  }, []);

  useEffect(() => {
    data && setStudents(data);
  }, [data]);

  return status === STATUSES.LOADING ? (
    <Card>
      <Loader />
    </Card>
  ) : (
    <>
      <Card>
        <CardHeader
          title="Students"
          icon="street-view"
          titleTag="h4"
          className="bg-light"
          endEl={
            <Link to="/students/add">
              <IconButton
                variant="falcon-primary"
                size="sm"
                iconAlign="right"
                icon="plus"
                iconClassName="ms-1"
              >
                Add Student
              </IconButton>
            </Link>
          }
        />
        <Card.Body>
          <AdvanceTableWrapper
            columns={studentsTableColumns}
            data={students}
            pagination
            perPage={10}
            extra={'extra'}
          >
            <Row className="flex-end-center mb-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={students.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default Students;
