import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Image, Row } from 'react-bootstrap';
import WizardInput from './WizardInput';

const StudentForm = ({ register, errors, setValue }) => {
  const gender = [
    { id: 'Girl', name: 'Girl' },
    { id: 'Boy', name: 'Boy' },
    { id: 'Other', name: 'Other' }
  ];

  const [previewImage, setPreviewImage] = useState(null);

  const handleFileChange = event => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
  };

  return (
    <>
      <Row>
        <Col xs={4}></Col>
        <Col>
          {previewImage ? (
            <Image
              src={previewImage}
              alt="Preview"
              style={{ maxWidth: '200px' }}
              rounded
              thumbnail
            />
          ) : (
            ''
          )}
        </Col>
      </Row>

      <Row>
        <Col xs={4}></Col>
        <Col>
          <small>Image must be JPEG | PNG | JPG</small>
        </Col>
      </Row>

      <WizardInput
        type="file"
        label="Image"
        name="photoPath"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('photoPath', {
            required: 'Photo name is required'
          })
        }}
        onChange={handleFileChange}
      />

      <WizardInput
        type="text"
        label="Name"
        name="studentName"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('studentName', {
            required: 'Student name is required'
          })
        }}
      />

      <WizardInput
        type="text"
        label="Name in Urdu"
        name="nameInUrdu"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('nameInUrdu', {
            required: 'Name in Urdu is required'
          })
        }}
      />

      <WizardInput
        type="select"
        label="Gender"
        name="gender"
        placeholder="Select gender"
        errors={errors}
        options={gender}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('gender', {
            required: 'Gender is required'
          })
        }}
      />

      <WizardInput
        type="date"
        label="Date of Birth"
        name="birthDate"
        errors={errors}
        setValue={setValue}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          placeholder: 'Date of Birth',
          ...register('birthDate')
        }}
      />

      <WizardInput
        type="text"
        label="Local/Domicile"
        name="local"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('local', {
            required: 'Local/Domicile is required'
          })
        }}
      />

      <WizardInput
        type="text"
        label="Father name"
        name="father_name"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('father_name', {
            required: 'Father Name is required'
          })
        }}
      />

      <WizardInput
        type="text"
        label="Mother name"
        name="mother_name"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('mother_name', {
            required: 'Mother Name is required'
          })
        }}
      />

      <WizardInput
        type="text"
        label="Father CNIC"
        name="father_cnic"
        errors={errors}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          ...register('father_cnic', {
            required: 'Father CNIC is required',
            pattern: {
              value: /^[0-9]+$/,
              message: 'Please enter valid CNIC e.g 500001111119'
            }
          })
        }}
      />
    </>
  );
};

StudentForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired
};

export default StudentForm;
