export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/'
    }
  ]
};
export const academicRoutes = {
  label: 'academics',
  children: [
    {
      name: 'Basic info',
      icon: 'info',
      to: '/basic_info',
      active: true
    },
    {
      name: 'Students',
      icon: 'user-graduate',
      to: '/students',
      active: true
    },
    // {
    //   name: 'Students',
    //   icon: 'users',
    //   to: '/app/kanban',
    //   active: true
    // },
    {
      name: 'Guardians',
      icon: 'street-view',
      to: '/guardians',
      active: true
    }
    // {
    //   name: 'Signatures',
    //   icon: 'signature',
    //   to: '/app/kanban',
    //   active: true
    // },
    // {
    //   name: 'Student Termination',
    //   icon: 'eject',
    //   to: '/app/kanban',
    //   active: true
    // },
    // {
    //   name: 'Grade Placement',
    //   icon: 'pencil-ruler',
    //   to: '/app/kanban',
    //   active: true
    // }
  ]
};

export const operations = {
  label: 'operations',
  children: [
    {
      name: 'Campuses',
      icon: 'school',
      to: '/campuses',
      active: true
    }
  ]
};

export const finance = {
  label: 'finance',
  children: [
    {
      name: 'Fee Structure',
      icon: 'address-card',
      to: '/finance/fee-structure',
      active: true
    }
    // {
    //   name: 'Record Fee Payment',
    //   icon: 'money-check',
    //   to: '#',
    //   active: true
    // },
    // {
    //   name: 'Recevied Fee',
    //   icon: 'wallet',
    //   to: '/app/kanban',
    //   active: true
    // }
  ]
};

export const humanResource = {
  label: 'Human Resource',
  children: [
    // {
    //   name: 'Employees',
    //   icon: 'user-tie',
    //   to: '/employees',
    //   active: true
    // },
    // {
    //   name: 'Bank Accounts',
    //   icon: 'building',
    //   to: '/customers',
    //   active: true
    // }
  ]
};

export default [
  dashboardRoutes,
  academicRoutes,
  operations,
  finance,
  humanResource
];
