import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'common/constants';

export const addGuardian = createAsyncThunk(
  'guardian/add',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/guardians`, data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGuardians = createAsyncThunk(
  'guardian/dash',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/guardians`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const guardianFilter = createAsyncThunk(
  'guardian/checkGurdian',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/guardians/filter`, data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteGuardian = createAsyncThunk(
  'guardian/delete',
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`${BASE_URL}/guardians/${id}`);
      return id;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const showGuardian = createAsyncThunk(
  'guardian/show',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/guardians/${id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateGurdian = createAsyncThunk(
  'guardian/update',
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/guardians/${id}`,
        formData
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
