import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WizardInput from './WizardInput';
import { useSelector } from 'react-redux';
import { uniqueArrayOfObjects } from 'utils/unique';
import { Alert, Row, Col } from 'react-bootstrap';

const SeatForm = ({ register, errors, setValue, data }) => {
  const feeStructure = useSelector(state => state.feeStructure);
  const [campus, setCampus] = useState(null);
  const [campusGrade, setCampusGrade] = useState([]);
  const [allocatedSeats, setAllocatedSeats] = useState([]);

  const handleOnChange = e => {
    setCampus(e.target.value);
  };

  const handleOnGrade = e => {
    let filterGrade = campusGrade.filter(
      grade => grade.id === parseInt(e.target.value)
    );
    setAllocatedSeats(filterGrade);
  };

  useEffect(() => {
    if (campus) {
      let seats = data.filter(e => e.id == campus);
      if (seats.length >= 1) {
        setCampusGrade(
          seats[0].seats.map(e => {
            return {
              id: e.id,
              name: e.equivalent,
              allocatedSeats: e.allocatedSeats,
              girls: e.girlsCount,
              boys: e.girlsCount
            };
          })
        );
      }
    }
  }, [campus]);

  let sessionYears = uniqueArrayOfObjects(feeStructure.data, 'year').map(
    session => {
      return {
        id: session.year,
        name: session.year
      };
    }
  );

  return (
    <>
      <h1 className="text-center mb-1">1. Select a Seat</h1>

      <Row>
        <Col xs={4}></Col>
        <Col xs={4}>
          {allocatedSeats?.map(seat => (
            <>
              <Alert
                variant={seat.allocatedSeats > 0 ? 'success' : 'warning'}
                className="mb-3 mt-3"
              >
                <div key={seat}>
                  {seat.allocatedSeats === 0 ? (
                    <small>
                      Warning: Allocated seat found 0 <br />
                    </small>
                  ) : (
                    ''
                  )}{' '}
                  <strong>Grade: </strong> {seat.name} <br />
                  <strong>Allocated Seats : </strong> {seat.allocatedSeats}
                  <br />
                  <strong>Girls : </strong> {seat.boys} <br />
                  <strong>Boys</strong> {seat.girls}
                </div>
              </Alert>
            </>
          ))}
        </Col>
      </Row>

      <WizardInput
        label="Campus"
        type="select"
        name="campusName"
        errors={errors}
        placeholder="--- Select Campus ---"
        options={data}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('campusName', {
            required: 'Campus is required'
          })
        }}
        handleOnChange={handleOnChange}
      />

      <WizardInput
        label="Enrolled into (Session)"
        type="select"
        name="session"
        placeholder="--- Select Session ---"
        options={sessionYears}
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('session', {
            required: 'Session is required'
          })
        }}
      />

      <WizardInput
        label="Enrolled Into (Grade)"
        type="select"
        name="grade"
        placeholder="--- Select Grade ---"
        options={campusGrade}
        errors={errors}
        formGroupProps={{ className: 'mb-3' }}
        formControlProps={{
          ...register('grade', {
            required: 'Grade is required'
          })
        }}
        handleOnChange={handleOnGrade}
      />

      <WizardInput
        type="date"
        label="Date of enrollment"
        name="dateOfEnrollment"
        errors={errors}
        setValue={setValue}
        formGroupProps={{
          className: 'mb-3'
        }}
        formControlProps={{
          placeholder: 'Date of Enrollment',
          ...register('dateOfEnrollment', {
            required: 'Date of enrollment is required'
          })
        }}
      />
    </>
  );
};

SeatForm.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setValue: PropTypes.func.isRequired,
  data: PropTypes.array
};

export default SeatForm;
