import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from 'common/constants';

export const fetchFeeStructure = createAsyncThunk(
  'feeStructure/fetch',
  async () => {
    const response = await axios(`${BASE_URL}/fee-structure`);
    return response.data;
  }
);

export const updateFeeStructure = createAsyncThunk(
  'updateFeeStructure/update',
  async data => {
    const response = await axios.patch(`${BASE_URL}/fee-structure`, data);
    return response.data;
  }
);
