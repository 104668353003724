import { configureStore } from '@reduxjs/toolkit';
import employeesReducer from './features/employees/employeeSlice';
import basicInforReducer from './features/basicInfo/basicInfoSlice';
import feeStructureReducer from './features/feeStructure/feeStructureSlice';
import campusReducer from './features/campus/campusSlice';
import commonReducer from './features/common/commonSlice';
import guardianReducer from './features/guardians/guardianSlice';
import studentReducer from './features/student/studentSlice';

export const store = configureStore({
  reducer: {
    employees: employeesReducer,
    basicInfo: basicInforReducer,
    feeStructure: feeStructureReducer,
    campuses: campusReducer,
    common: commonReducer,
    guardian: guardianReducer,
    student: studentReducer
  }
});
