import { createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from 'common/constants';
import axios from 'axios';

export const fetchBasicInfo = createAsyncThunk('basicInfo/fetch', async () => {
  const response = await axios.get(`${BASE_URL}/basic-info`);
  return response.data;
});

export const updateBasicInfo = createAsyncThunk(
  'updateBasicInfo/update',
  async ({ id, data }) => {
    const response = await axios.patch(`${BASE_URL}/basic-info/${id}`, data);
    return response.data;
  }
);
