import React from 'react';
import { Card, Col, Row, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import avatar from '../../../assets/img/images/avatar.png';

const ProfileCard = ({ data }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="g-3 align-items-center">
          <Col md={6} lg={12} className="text-center">
            <Image
              src={avatar}
              alt="guardian image"
              style={{ maxWidth: '100px' }}
            />
          </Col>
          <Col md={6} lg={12}>
            <Row className="row-cols-1 g-0">
              <Col className="text-center">
                <h4>
                  {data.title} &nbsp;
                  {data.name}
                </h4>
                <h5 className="mb-1 text-800 fs-0">
                  {data.guardianId} | {data.email}
                </h5>
                <p className="mb-0 fs--1">{data.postalAddress}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ProfileCard.propTypes = {
  data: PropTypes.object
};

export default ProfileCard;
