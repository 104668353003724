import team1 from 'assets/img/logos/avatar.png';

export const rawNewNotifications = [
  {
    id: 1,
    avatar: {
      src: team1,
      size: '2xl'
    },
    children: '<strong>Javed</strong> Created a new Student ',
    time: '1 month ago',
    emoji: '🧑‍🎓',
    className: 'rounded-0 border-x-0 border-300 border-bottom-0',
    to: '#!',
    unread: true
  }
];
