import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/common/Header';
import { Card, Button, Col, Row, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Loading from '../../components/common/Loader';
import placeholderImage from '../../assets/img/placeholder.webp';
import { STATUSES } from 'common/constants';
import { useDispatch } from 'react-redux';
import { fetchCampuses } from 'redux/features/campus/actions';
import { EditCampusSignatureModal } from './EditCampusSignatureModal';

const BasicInfo = ({ setEdit }) => {
  const dispatch = useDispatch();

  const { data, status } = useSelector(state => state.basicInfo);
  const { data: campuses } = useSelector(state => state.campuses);

  useEffect(() => {
    dispatch(fetchCampuses());
  }, []);

  // Edit Campus Signature
  const [signatureId, setSignatureId] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = id => {
    setSignatureId(id);
    setShow(true);
  };

  return status === STATUSES.LOADING ? (
    <Card>
      <Loading />
    </Card>
  ) : (
    <div>
      <Header
        title="Basic Info"
        icon="info"
        btnLink="/"
        btnTitle="Go Back"
      ></Header>
      <Card>
        <Card.Body>
          <Row>
            <h5>Basic Information</h5>
            <hr />

            <Col md={2}>
              <strong>Name</strong>
            </Col>
            <Col>{data.name}</Col>
            <Col md={2}>
              <strong>Logo</strong>
            </Col>
            <Col>
              <Image
                src={data.logo_img ? data.logo_img : placeholderImage}
                width="55%"
                rounded
                thumbnail
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <h5>Signatures</h5>
            <hr />

            <Col md={3}>
              <strong>CEO</strong>
            </Col>
            <Col>
              <Image
                src={
                  data.ceo_signature_img
                    ? data.ceo_signature_img
                    : placeholderImage
                }
                width="60%"
                rounded
                thumbnail
              />
            </Col>
            <Col md={3}>
              <strong>Executive Program Director</strong>
            </Col>
            <Col>
              <Image
                src={
                  data.epd_signature_img
                    ? data.epd_signature_img
                    : placeholderImage
                }
                width="60%"
                rounded
                thumbnail
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={3}>
              <strong>Exective Admin officer</strong>
            </Col>
            <Col>
              <Image
                src={
                  data.eao_signature_img
                    ? data.eao_signature_img
                    : placeholderImage
                }
                width="60%"
                rounded
                thumbnail
              />
            </Col>
            <Col md={3}>
              <strong>Executive Director for Student Assessment</strong>
            </Col>
            <Col>
              <Image
                src={
                  data.edsa_signature_img
                    ? data.edsa_signature_img
                    : placeholderImage
                }
                width="60%"
                rounded
                thumbnail
              />
            </Col>
          </Row>
          <Button className="mt-4" onClick={() => setEdit(true)}>
            Edit
          </Button>
        </Card.Body>
      </Card>
      <Card className="mt-5">
        <Card.Body>
          <Row mt={4}>
            <h5>Campus Signatures</h5>
            <hr />

            {campuses.length &&
              campuses.map((cmps, idx) => (
                <Col key={idx} md={4} className="mt-3">
                  <strong>{cmps.name} Signature</strong>
                  <br />
                  <Image
                    src={cmps.signature ? cmps.signature : placeholderImage}
                    width="60%"
                    thumbnail
                    rounded
                  />{' '}
                  <br />
                  <Button variant="link" onClick={() => handleShow(cmps.id)}>
                    Edit Signature
                  </Button>
                </Col>
              ))}
          </Row>
        </Card.Body>
      </Card>
      <EditCampusSignatureModal
        show={show}
        handleClose={handleClose}
        signatureId={signatureId}
        setShow={setShow}
      />
    </div>
  );
};

BasicInfo.propTypes = {
  setEdit: PropTypes.func
};
export default BasicInfo;
