import { createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from 'common/constants';
import axios from 'axios';

export const fetchAcademicLevels = createAsyncThunk(
  'common/academicLevels',
  async () => {
    const response = await axios.get(`${BASE_URL}/levels`);
    return response.data;
  }
);
