import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { columnLeftStyle, columnRightStyle } from 'common/commonStyles';

const PersonalInfo = ({ data }) => {
  return (
    <>
      <h5>Personal Info</h5>
      <Table borderless size="sm">
        <tbody>
          <tr>
            <td style={columnLeftStyle}>Full Name : </td>
            <td style={columnRightStyle}>{data.fullname}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Name Urdu : </td>
            <td style={columnRightStyle}>{data.fullname_urdu}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Student ID : </td>
            <td style={columnRightStyle}>{data.student_id}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Gender : </td>
            <td style={columnRightStyle}>{data.gender}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Date Of Birth : </td>
            <td style={columnRightStyle}>{data.dateof_birth}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Domicile / Local : </td>
            <td style={columnRightStyle}>{data.domicile}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Mother Name : </td>
            <td style={columnRightStyle}>{data.mother_name}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Father Name : </td>
            <td style={columnRightStyle}>{data.father_name}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Father CNIC : </td>
            <td style={columnRightStyle}>{data.father_cnic}</td>
          </tr>
        </tbody>
      </Table>

      <h5>Enrollment Info</h5>
      <Table borderless size="sm">
        <tbody>
          <tr>
            <td style={columnLeftStyle}>Campus : </td>
            <td style={columnRightStyle}>{data.campus.name}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Session : </td>
            <td style={columnRightStyle}>{data.session}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Date of Enrollment : </td>
            <td style={columnRightStyle}>{data.date_of_enrolment}</td>
          </tr>
          <tr>
            <td style={columnLeftStyle}>Grade : </td>
            <td style={columnRightStyle}>{data.grade.equivalent}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

PersonalInfo.propTypes = {
  data: PropTypes.object
};

export default PersonalInfo;
