import Header from 'components/common/Header';
import React, { useState } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addGuardian, guardianFilter } from 'redux/features/guardians/actions';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AddGuardian = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [guardianExists, setGuardianExists] = useState(false);
  const [guardianData, setGuardianData] = useState([]);
  const [btnDisable, setBtnDisable] = useState(false);
  const [cnicLoader, setCnicLoader] = useState(false);

  // Handle Form
  const { register, handleSubmit } = useForm();

  const checkIfCnicExists = e => {
    let cnic = e.target.value;

    if (cnic) {
      setCnicLoader(true);
      dispatch(guardianFilter({ cnic: cnic }))
        .unwrap()
        .then(payload => {
          setGuardianExists(true);
          setGuardianData(payload);
          setCnicLoader(false);
        })
        .catch(() => {
          setGuardianExists(false);
          setCnicLoader(false);
          setGuardianData([]);
        });
    } else {
      setGuardianExists(false);
    }
  };

  const onSubmit = data => {
    setBtnDisable(true);

    const form = new FormData();

    form.append('cnic', data.cnic);
    form.append('title', data.title);
    form.append('name', data.name);
    form.append('guardianId', data.guardianId);
    form.append('postalAddress', data.postalAddress);
    form.append('primaryNumber', data.primaryNumber);
    form.append('secondaryNumber', data.secondaryNumber);
    form.append('email', data.number);

    // Dispatch
    dispatch(addGuardian(data))
      .unwrap()
      .then(() => {
        toast.success('Guardian has been added');
        setBtnDisable(false);
        navigate('/guardians');
      })
      .catch(e => {
        if (Array.isArray(e.message)) {
          e.message.slice(0, 2).map(e => toast.error(e));
        } else {
          toast.error(e.message);
        }
        setBtnDisable(false);
      });
  };

  return (
    <div>
      <Header
        title="Add Guardian"
        icon="plus"
        btnLink="/"
        btnTitle="Go Back"
      ></Header>

      <Card className="mb-3">
        <Card.Header as="h5">Personal Details</Card.Header>
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="CNIC" className="mb-2">
              <Row>
                <Col xs={2} className="d-flex justify-content-end pt-1">
                  <Form.Label>CNIC Number:</Form.Label>
                </Col>
                <Col xs={4}>
                  <Form.Control
                    type="text"
                    placeholder="e.g 00000-0000000-0"
                    {...register('cnic')}
                    onBlur={e => checkIfCnicExists(e)}
                  />
                </Col>
                <Col>
                  {cnicLoader ? (
                    <Spinner animation="border" variant="secondary" />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
            </Form.Group>
            {guardianExists ? (
              <div>
                <Row>
                  <Col xs={2}></Col>
                  <Col>
                    <strong className="text-success">
                      CNIC Already exists <FontAwesomeIcon icon="check" />
                    </strong>
                    <br />
                    <strong>CNIC:</strong> {guardianData.cnic} <br />
                    <strong>Title:</strong> {guardianData.title}.{' '}
                    {guardianData.name}
                    <br />
                    <strong>Guardian ID:</strong> {guardianData.guardianId}
                    <br />
                    <strong>Postal Address:</strong>{' '}
                    {guardianData.postalAddress}
                    <br />
                    <strong>Cell Number (Primary):</strong>{' '}
                    {guardianData.primaryNumber}
                    <br />
                    <strong>Secondary Number (Primary):</strong>{' '}
                    {guardianData.secondaryNumber}
                    <br />
                    <strong>Email:</strong> {guardianData.email}
                    <br />
                  </Col>
                </Row>
              </div>
            ) : (
              <>
                <Form.Group controlId="title" className="mb-2">
                  <Row>
                    <Col xs={2} className="d-flex justify-content-end pt-1">
                      <Form.Label>Title:</Form.Label>
                    </Col>
                    <Col xs={4}>
                      <Form.Select name="title" {...register('title')}>
                        <option value="Mr">Mr. </option>
                        <option value="Miss">Miss. </option>
                        <option value="Mrs">Mrs. </option>
                        <option value="Ms">Ms. </option>
                        <option value="Dr">Dr. </option>
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group controlId="name" className="mb-2">
                  <Row>
                    <Col xs={2} className="d-flex justify-content-end pt-1">
                      <Form.Label>Name:</Form.Label>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        type="text"
                        name="name"
                        {...register('name')}
                      />
                    </Col>
                    <Col xs={2}>
                      <span className="small">As in CNIC</span>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group controlId="guardianID" className="mb-2">
                  <Row>
                    <Col xs={2} className="d-flex justify-content-end pt-1">
                      <Form.Label>Guardian ID:</Form.Label>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        type="text"
                        name="guardianId"
                        {...register('guardianId')}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="postalAddress" className="mb-2">
                  <Row>
                    <Col xs={2} className="d-flex justify-content-end pt-1">
                      <Form.Label>Postal Address:</Form.Label>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        type="text"
                        name="postalAddress"
                        {...register('postalAddress')}
                      />
                    </Col>
                    <Col xs={5}>
                      <span className="small">
                        Complete postal address for offical correspondence.
                      </span>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="number" className="mb-2">
                  <Row>
                    <Col xs={2} className="d-flex justify-content-end pt-1">
                      <Form.Label>Number (Primary):</Form.Label>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        type="text"
                        name="primaryNumber"
                        {...register('primaryNumber')}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="secondaryNumber" className="mb-2">
                  <Row>
                    <Col xs={2} className="d-flex justify-content-end pt-1">
                      <Form.Label>Cell Number:</Form.Label>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        type="text"
                        name="secondaryNumber"
                        {...register('secondaryNumber')}
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="email" className="mb-2 ">
                  <Row>
                    <Col xs={2} className="d-flex justify-content-end pt-1">
                      <Form.Label>Email:</Form.Label>
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        type="text"
                        name="email"
                        {...register('email')}
                      />
                    </Col>
                    <Col xs={4}>
                      <span className="small">
                        A valid email address if required.
                        <br /> Parent and Student apps will be registered with
                        this email id
                      </span>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group controlId="email" className="mb-2">
                  <Row>
                    <Col
                      xs={2}
                      className="d-flex justify-content-end pt-1"
                    ></Col>
                    <Col xs={2}>
                      <Button bg="primary" type="submit" disabled={btnDisable}>
                        {btnDisable ? (
                          <Spinner animation="grow" variant="light" size="sm" />
                        ) : (
                          ''
                        )}
                        &nbsp; Save
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
              </>
            )}

            <Row>
              <Col md="12 mt-2"></Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddGuardian;
