import { createSlice } from '@reduxjs/toolkit';
import { STATUSES } from 'common/constants';
import { fetchAcademicLevels } from './actions';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    academicLevels: [],
    error: null,
    status: STATUSES.IDLE
  },
  extraReducers(builder) {
    builder
      // Fetch academic levels
      .addCase(fetchAcademicLevels.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.academicLevels = action.payload;
        state.error = null;
      })
      .addCase(fetchAcademicLevels.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = action.payload;
      })
      .addCase(fetchAcademicLevels.pending, state => {
        state.status = STATUSES.LOADING;
        state.error = null;
      });
  }
});

export default commonSlice.reducer;
