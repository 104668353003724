import React from 'react';
import { Card } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';

const Meeting = () => {
  return (
    <Card>
      <Card.Body className="p-4">
        <h4 className="text-800 pb-5">Meeting & Management</h4>

        <IconButton
          icon="calender"
          variant="success"
          iconClassName="me-2"
          className="w-100 mb-2"
        >
          Check meeting schedules
        </IconButton>

        <IconButton
          icon="calender"
          variant="primary"
          iconClassName="me-2"
          className="w-100"
        >
          Check Monday.com Tasks
        </IconButton>
      </Card.Body>
    </Card>
  );
};

export default Meeting;
