import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loader = () => {
  return (
    <Spinner
      animation="border"
      variant="primary"
      style={{
        width: '100px',
        height: '100px',
        margin: '100px auto'
      }}
    />
  );
};

export default Loader;
