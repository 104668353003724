const autoCompleteInitialItem = [
  {
    id: 5,
    catagories: 'suggestedFilters',
    url: '/',
    key: 'Students',
    text: 'Browse Students',
    type: 'info'
  },
  {
    id: 5,
    catagories: 'suggestedFilters',
    url: '/',
    key: 'Campus',
    text: 'Browse Campuses',
    type: 'info'
  },
  {
    id: 5,
    catagories: 'suggestedFilters',
    url: '/',
    key: 'Employees',
    text: 'Browse Employees',
    type: 'info'
  },
  {
    id: 5,
    catagories: 'suggestedFilters',
    url: '/',
    key: 'Fee Management',
    text: 'Browse Fee Management',
    type: 'info'
  }
];

export default autoCompleteInitialItem;
