import React from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CampusIcon from 'assets/img/icons/other.svg';
import FalconCardHeader from 'components/common/CardHeader';

const Campus = ({ data: { title, campusCount } }) => (
  <Card className={'h-100'}>
    <FalconCardHeader
      title="Campuses"
      light={false}
      titleTag="h5"
      className="pb-3"
    ></FalconCardHeader>
    <Card.Body className="pt-2">
      <Row className="g-0 h-100 align-items-center">
        <Col as={Flex} alignItems="center">
          <img className="me-3" src={CampusIcon} alt="" height="60" />
          <div>
            <h6 className="mb-2">{title}</h6>
            <div className="fs--2 fw-semi-bold"></div>
          </div>
        </Col>
        <Col xs="auto" className="text-center ps-2">
          <div className="fs-4 fw-normal font-sans-serif text-primary mb-1 lh-1">
            {campusCount}
          </div>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

Campus.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default Campus;
