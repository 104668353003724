import Header from 'components/common/Header';
import StudentWizard from './partials/wizard/Wizard';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const AddStudents = () => {
  return (
    <div>
      <Header
        title="Add Student"
        icon="plus"
        btnLink="/"
        btnTitle="Go Back"
      ></Header>

      <Row className="my-2 g-4">
        <Col lg={12} xl={12} xxl={12} className="mt-0 h-100">
          <StudentWizard variant="pills" progressBar={true} validation={true} />
        </Col>
      </Row>
    </div>
  );
};

export default AddStudents;
