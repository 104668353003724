import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function ControlledInput({ value, onChange, name, index }) {
  const [innerValue, setInnerValue] = useState(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);
  return (
    <input
      type="text"
      className="form-control"
      size="sm"
      value={innerValue}
      name={name}
      onChange={e => setInnerValue(e.target.value)}
      onBlur={() => onChange(innerValue, name, index)}
      style={{ textAlign: 'right' }}
    />
  );
}

ControlledInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  index: PropTypes.number
};

export default ControlledInput;
