import { createSlice } from '@reduxjs/toolkit';
import { STATUSES } from 'common/constants';
import { fetchBasicInfo, updateBasicInfo } from './actions';

export const basicInfoSlice = createSlice({
  name: 'basicInfo',
  initialState: {
    data: [],
    error: '',
    status: STATUSES.IDLE
  },
  reducers: {},
  extraReducers(builder) {
    // Fetch Basic Info
    builder.addCase(fetchBasicInfo.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(fetchBasicInfo.rejected, (state, action) => {
      state.error = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(fetchBasicInfo.pending, (state, action) => {
      state.error = action.payload;
      state.status = STATUSES.LOADING;
    });

    // Update Basic Info
    builder.addCase(updateBasicInfo.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(updateBasicInfo.rejected, (state, action) => {
      state.error = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(updateBasicInfo.pending, (state, action) => {
      state.error = action.payload;
      state.status = STATUSES.LOADING;
    });
  }
});

export default basicInfoSlice.reducer;
