import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchBasicInfo } from 'redux/features/basicInfo/actions';
import BasicInfo from './BasicInfo';
import EditBasicInfo from './EditBasicInfo';

const Info = () => {
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    setEdit(false);
    dispatch(fetchBasicInfo());
  }, [dispatch]);

  return (
    <div>
      {edit ? (
        <EditBasicInfo setEdit={setEdit} />
      ) : (
        <BasicInfo setEdit={setEdit} />
      )}
    </div>
  );
};

export default Info;
