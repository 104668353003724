import React from 'react';
import { Dropdown, Badge } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import img from '../../../assets/img/logos/avatar.png';

export const employeeTableColumns = [
  {
    accessor: 'employee_name',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { employee_name, photo } = rowData.row.original;
      return (
        <Link to="/">
          <Flex alignItems="center">
            {photo === 'placeholder.jpg' ? (
              <Avatar src={img} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={employee_name} className="me-2" />
            )}
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{employee_name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'email',
    Header: 'Email'
  },
  {
    accessor: 'cell',
    Header: 'Phone'
  },
  {
    accessor: 'position',
    Header: 'Position',
    Cell: rowData => {
      const { position } = rowData.row.original;
      return <Badge bg="dark">{position}</Badge>;
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: () => {
      return (
        <CardDropdown iconClassName="fs--1">
          <div className="py-2">
            <Dropdown.Item>Edit</Dropdown.Item>
            <Dropdown.Item>Delete</Dropdown.Item>
          </div>
        </CardDropdown>
      );
    }
  }
];
