import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateSignature } from 'redux/features/campus/actions';

export const EditCampusSignatureModal = ({
  show,
  handleClose,
  signatureId,
  setShow
}) => {
  const dispatch = useDispatch();

  const [image, setImage] = useState('');

  const handleImageChange = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async data => {
    const formData = new FormData();
    formData.append('id', data.id);
    formData.append('file', data.file[0]);

    dispatch(updateSignature({ data: formData }));

    reset();
    setImage(null);

    setShow(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Signature</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <input type="hidden" {...register('id')} value={signatureId} />
            <input
              type="file"
              {...register('file')}
              onChange={handleImageChange}
              className="form-control"
            />
            {image && (
              <img
                src={image}
                alt="Selected Image"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

EditCampusSignatureModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  signatureId: PropTypes.number,
  setShow: PropTypes.func
};
