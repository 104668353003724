import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { capitalize } from 'helpers/utils';

const GuardianStudents = ({ data }) => {
  return (
    <>
      <h5>Students</h5>
      {data.students && !data.students.length ? 'No students found' : ''}

      {data.students &&
        data.students.map(student => (
          <>
            <Link
              to={`/students/${student.id}`}
              style={{ textDecoration: 'none' }}
            >
              <Card
                className="mb-2"
                style={{
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  border: '1px solid #DDD'
                }}
              >
                <Card.Body>
                  <Flex alignItems="center">
                    <Avatar
                      size="xl"
                      src={student.photoPath}
                      className="me-2"
                    />

                    <div className="flex-1">
                      <h5 className="mb-0 fs--1">
                        <span>{student.fullname}</span> <br />
                        <small>{student.student_id}</small> <br />
                        <small>
                          {student.status === 'new_enrolment'
                            ? 'New Enrollment'
                            : ''}
                        </small>{' '}
                        <br />
                      </h5>
                    </div>
                    <div>
                      <h5 className="mb-0 fs--1">
                        <strong>Relation with guardian</strong> <br />
                        {capitalize(student.relation_with_guardian)}
                      </h5>
                    </div>
                  </Flex>
                </Card.Body>
              </Card>
            </Link>
          </>
        ))}
    </>
  );
};

GuardianStudents.propTypes = {
  data: PropTypes.object
};

export default GuardianStudents;
