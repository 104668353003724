import React, { useEffect, useState } from 'react';
import { Modal, Button, CloseButton, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { createCampus, updateCampus } from 'redux/features/campus/actions';
import { useSelector } from 'react-redux';

const UpdateCampusModal = () => {
  const dispatch = useDispatch();
  const { academicLevels } = useSelector(state => state.common);
  const { showModel, edit, singleCampus } = useSelector(
    state => state.campuses
  );
  const [form, setForm] = useState(singleCampus);
  useEffect(() => {
    setForm(singleCampus);
  }, [singleCampus]);
  // Academic Levels & Status values
  const statusData = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
  ];
  // Form data
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = data => {
    const newData = {
      ...data,
      academicLevels: data.academicLevels.map(level => level.id),
      status: data.status.value
    };

    // API call
    dispatch(
      edit
        ? updateCampus({ id: singleCampus.id, data: newData })
        : createCampus(newData)
    )
      .unwrap()
      .then(response => {
        // setShow(false);
        toast.success('Campus has been added');
        reset();
        console.log(response);
      })
      .catch(e => {
        toast.error(e.message);
      });
  };

  return (
    <>
      <Modal
        size="lg"
        show={showModel}
        onHide={() => dispatch({ type: 'hide/model' })}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Update Campus</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => dispatch({ type: 'hide/model' })}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gx-2 gy-3">
              <Col md={12}>
                <Form.Group controlId="name">
                  <Form.Label>Campus Name</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={form.name}
                    placeholder="e.g"
                    {...register('name', {
                      required: 'Campus name is required'
                    })}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && errors.name.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="campusId">
                  <Form.Label>Unique ID</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={form.campusId}
                    placeholder="e.g AA1"
                    isInvalid={!!errors.campusId}
                    {...register('campusId', {
                      required: 'Unique is required'
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.campusId && errors.campusId.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    defaultValue={form.address}
                    rows={2}
                    placeholder="e.g Block C1"
                    isInvalid={!!errors.address}
                    {...register('address', {
                      required: 'Address is required'
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address && errors.address.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="status">
                  <Form.Label>Status</Form.Label>
                  <Controller
                    name="status"
                    defaultValue={form.status}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={statusData}
                        defaultInputValue={
                          form.status
                            ? form.status.charAt(0).toUpperCase() +
                              form.status.slice(1)
                            : 'Active'
                        }
                        placeholder="Select..."
                        classNamePrefix="react-select"
                        isInvalid={!!errors.status}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.status?.stat || 'Status is required'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="academic_levels">
                  <Form.Label>Academic Levels</Form.Label>
                  <Controller
                    name="academicLevels"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={
                      Object.keys(form).length
                        ? form.academicLevels.map(e => {
                            console.log(e);
                            return {
                              id: e.id,
                              value: e.name,
                              label: e.name
                            };
                          })
                        : null
                    }
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={academicLevels.map(el => {
                          return {
                            id: el.id,
                            value: el.name,
                            label: el.name
                          };
                        })}
                        placeholder="Select..."
                        classNamePrefix="react-select"
                        isMulti
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Button className="mt-2" type="submit">
                  {edit ? 'Update' : 'Save'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateCampusModal;
