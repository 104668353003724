import { React, useState } from 'react';
import Header from 'components/common/Header';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

const AddEmployee = () => {
  const [date, setDate] = useState(null);

  const countries = [
    { value: 1, label: 'Afghanistan' },
    { value: 2, label: 'Albania' },
    { value: 3, label: 'Algeria' },
    { value: 4, label: 'Andorra' },
    { value: 5, label: 'Angola' },
    { value: 6, label: 'Antigua and Barbuda' },
    { value: 7, label: 'Argentina' },
    { value: 8, label: 'Armenia' },
    { value: 9, label: 'Australia' },
    { value: 10, label: 'Austria' },
    { value: 11, label: 'Azerbaijan' },
    { value: 12, label: 'Bahamas' },
    { value: 13, label: 'Bahrain' },
    { value: 14, label: 'Bangladesh' },
    { value: 15, label: 'Barbados' },
    { value: 16, label: 'Belarus' },
    { value: 17, label: 'Belgium' },
    { value: 18, label: 'Belize' },
    { value: 19, label: 'Benin' },
    { value: 20, label: 'Bhutan' },
    { value: 21, label: 'Bolivia (Plurinational State of)' },
    { value: 22, label: 'Bosnia and Herzegovina' },
    { value: 23, label: 'Botswana' },
    { value: 24, label: 'Brazil' },
    { value: 25, label: 'Brunei Darussalam' },
    { value: 26, label: 'Bulgaria' },
    { value: 27, label: 'Burkina Faso' },
    { value: 28, label: 'Burundi' },
    { value: 29, label: 'Cabo Verde' },
    { value: 30, label: 'Cambodia' },
    { value: 31, label: 'Cameroon' },
    { value: 32, label: 'Canada' },
    { value: 33, label: 'Central African Republic' },
    { value: 34, label: 'Chad' },
    { value: 35, label: 'Chile' },
    { value: 36, label: 'China' },
    { value: 37, label: 'Colombia' },
    { value: 38, label: 'Comoros' },
    { value: 39, label: 'Pakistan' }
  ];
  return (
    <div>
      <Header
        title="Add Employee"
        icon="plus"
        btnLink="/employees"
        btnTitle="Go Back"
      ></Header>

      <Card className="mb-3">
        <Card.Header as="h5">Personal Details</Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col md="4">
              <Form.Group controlId="Employee ID">
                <Form.Label>Employee ID</Form.Label>
                <Form.Control
                  type="text"
                  name="employeeId"
                  placeholder="e.g 001"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="employeeName">
                <Form.Label>Employee name</Form.Label>
                <Form.Control
                  type="text"
                  name="employeeName"
                  placeholder="e.g Seemak Baloch"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="alias">
                <Form.Label>Alias</Form.Label>
                <Form.Control type="text" name="alias" placeholder="Seemak" />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="nationality">
                <Form.Label>Nationlity</Form.Label>
                <Select
                  closeMenuOnSelect={false}
                  options={countries}
                  placeholder="Select Country"
                  classNamePrefix="react-select"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="cnicNumber">
                <Form.Label>CNIC</Form.Label>
                <Form.Control
                  type="text"
                  name="cnicNumber"
                  placeholder="50000-000000-9"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="dateOfBirth">
                <Form.Label>Date of Birth</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  className="form-control"
                  placeholderText="Select Date"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="e.g PIA Road, Lahore"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Scope</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Position</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Appointment Date</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Probationay Peroid</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Contract Expiary Date</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Starting Salary</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Photo</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Cell</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Landind Line</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group controlId="eventTitle">
                <Form.Label>Campus</Form.Label>
                <Form.Control
                  type="text"
                  name="eventTitle"
                  placeholder="Event Title"
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <Button bg="primary">Save</Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AddEmployee;
