import React from 'react';
import { Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import img from '../../../assets/img/logos/avatar.png';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { deleteStudent } from 'redux/features/student/actions';

const TableActions = ({ data }) => {
  const dispatch = useDispatch();
  // Delete
  const handleDelete = id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteStudent(id))
          .unwrap()
          .then(() =>
            Swal.fire('Deleted!', 'Student has been deleted', 'success')
          )
          .catch(error =>
            Swal.fire(
              'Error!',
              `Internal Server error ${error.message}`,
              'error'
            )
          );
      }
    });
  };
  return (
    <CardDropdown iconClassName="fs--1">
      <div className="py-2">
        <Dropdown.Item as={Link} to={`/students/edit/${data.id}`}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleDelete(data.id)}>
          Delete
        </Dropdown.Item>
        {data.suspend ? (
          <Dropdown.Item onClick={() => alert('This feature is disabled')}>
            Active
          </Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={() => alert('this feature is disaabled')}>
            Suspend
          </Dropdown.Item>
        )}
      </div>
    </CardDropdown>
  );
};

TableActions.propTypes = {
  data: PropTypes.object
};

export const studentsTableColumns = [
  {
    accessor: 'fullname',
    Header: 'Student',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { fullname, id, photoPath, student_id, grade } =
        rowData.row.original;
      return (
        <Link to={`/students/${id}`} style={{ textDecoration: 'none' }}>
          <Flex alignItems="center">
            {photoPath && photoPath !== '' ? (
              <Avatar src={photoPath} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" src={img} name={img} className="me-2" />
            )}
            <div className="flex-1">
              <h5 className="mb-0 fs--1">
                {fullname} <br />
                <small className="text-muted">{student_id}</small> <br />
                <small>{grade.equivalent}</small>
              </h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'guardian',
    Header: 'Guaridan',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const placeholder = true;
      const { guardian } = rowData.row.original;
      return (
        <Link
          to={`/guardians/${guardian.id}`}
          style={{ textDecoration: 'none' }}
        >
          <Flex alignItems="center">
            {placeholder ? (
              <Avatar src={img} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" src={img} name={placeholder} className="me-2" />
            )}
            <div className="flex-1">
              <h5 className="mb-0 fs--1">
                {guardian.title} {guardian.name} <br />
                <small>{guardian.guardianId}</small>
              </h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'none',
    Header: '',
    disableSortBy: true,
    cellProps: {
      className: 'text-end'
    },
    Cell: rowData => <TableActions data={rowData.row.original} />
  }
];
