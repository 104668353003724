import Header from 'components/common/Header';
import React, { useEffect, useState } from 'react';
import { Card, Button, ButtonGroup, Table, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFeeStructure,
  updateFeeStructure
} from 'redux/features/feeStructure/actions';
import { FeeTable } from './FeeStructureTable';
import { toast } from 'react-toastify';
import Loader from 'components/common/Loader';
import { STATUSES } from 'common/constants';

const FeeStructure = () => {
  // Redux Data
  const dispatch = useDispatch();
  const { data, status } = useSelector(state => state.feeStructure);

  // States
  const [IsEdit, setIsEdit] = useState(false);
  const [feeYear, setFeeYear] = useState(2023);
  const [feeData, setFeeData] = useState(data);

  // Years tabs data
  const yearGroup = [
    ...new Set(
      data.map(fee => {
        return fee.year;
      })
    )
  ].sort((a, b) => a - b);

  // Renders
  useEffect(() => {
    dispatch(fetchFeeStructure());
  }, [dispatch, IsEdit]);

  useEffect(() => {
    const newData = data
      .filter(ele => ele.year === feeYear)
      .sort((a, b) => a.id - b.id);
    setFeeData(newData);
  }, [data, feeYear]);

  // Handle change fields
  const handleChange = (value, name, idx) => {
    const newArr = feeData.map((obj, index) => {
      if (index === idx) {
        return {
          ...obj,
          [name]: value
        };
      }
      return obj;
    });
    setFeeData(newArr);
  };

  // Submit form
  const handleSubmit = e => {
    e.preventDefault();
    setIsEdit(false);
    dispatch(updateFeeStructure(feeData));
    toast.success('Fee structure has been updated');
  };

  return status === STATUSES.LOADING ? (
    <Card>
      <Loader />
    </Card>
  ) : (
    <>
      <Header
        title="Fee Structure"
        icon="address-card"
        btnLink={''}
        btnTitle={'Go Back'}
      />
      <Card>
        <Card.Body>
          <Row>
            <Col md={8}>
              <ButtonGroup aria-label="years-tabs" className="d-flex flex-wrap">
                {yearGroup.map((year, idx) => (
                  <Button
                    variant={feeYear === year ? 'primary active' : 'primary'}
                    disabled={IsEdit && year !== feeYear}
                    key={idx}
                    onClick={() => setFeeYear(year)}
                  >
                    {year}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
            <Col className="text-end">
              <h3 className="mt-2">Year: {feeYear} </h3>
            </Col>
          </Row>
          <Table bordered className="mt-2" responsive>
            <thead>
              <tr>
                <th>Level</th>
                <th>Tuition Fee</th>
                <th>Academic Fee</th>
                <th>Admission Fee</th>
                <th>Security Deposit</th>
              </tr>
            </thead>
            <tbody>
              {feeData.map((fee, idx) => {
                return (
                  <>
                    <FeeTable
                      idx={idx}
                      key={idx}
                      data={fee}
                      handleChange={handleChange}
                      IsEdit={IsEdit}
                    />
                  </>
                );
              })}
            </tbody>
          </Table>
          {IsEdit ? (
            <>
              <Button
                type="submit"
                style={{ float: 'right', marginLeft: '5px' }}
                onClick={handleSubmit}
                variant="success"
              >
                Save
              </Button>
              <Button
                style={{ float: 'right' }}
                variant="danger"
                onClick={() => setIsEdit(false)}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button onClick={() => setIsEdit(true)} style={{ float: 'right' }}>
              Edit
            </Button>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default FeeStructure;
