import React, { useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs, Card } from 'react-bootstrap';
import ProfileBanner from 'components/common/ProfileBanner';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { STATUSES } from 'common/constants';
import Loader from 'components/common/Loader';
import Header from 'components/common/Header';
import { showStudent } from 'redux/features/student/actions';
import ProfileCard from './partials/ShowStudentPartials/ProfileCard';
import PersonalInfo from './partials/ShowStudentPartials/PersonalInfo';
import StudentOtherInfo from './partials/ShowStudentPartials/StudentOtherInfo';

const ShowStudent = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [key, setKey] = useState('overview');

  const { singleStudent: student, status } = useSelector(
    state => state.student
  );

  useEffect(() => {
    dispatch(showStudent(id));
  }, []);

  return status === STATUSES.LOADING || !student ? (
    <Card>
      <Loader />
    </Card>
  ) : (
    <div>
      <Header
        title="Student Details"
        icon="street-view"
        btnLink="/"
        btnTitle="Go Back"
      ></Header>

      <ProfileCard data={student} />

      <ProfileBanner>
        <Tabs id="student-tabs" activeKey={key} onSelect={k => setKey(k)}>
          <Tab
            eventKey="overview"
            title="Overview"
            className="border-bottom border-x p-3"
          >
            <ProfileBanner.Body>
              <Row>
                <Col lg={6}>
                  <PersonalInfo data={student} />
                </Col>
                <Col lg={6}>
                  <StudentOtherInfo data={student} />
                </Col>
              </Row>
            </ProfileBanner.Body>
          </Tab>
          <Tab
            eventKey="payments"
            title="Academic Info"
            className="border-bottom border-x p-3"
          >
            <strong className="text-warning">
              Academic Records coming soon.
            </strong>
          </Tab>
        </Tabs>
      </ProfileBanner>
    </div>
  );
};

export default ShowStudent;
