import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav, ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import SeatForm from './SeatForm';
import StudentForm from './StudentForm';
import GuardianForm from './GuardianForm';
import AppContext, { AddStudentWizardContext } from 'context/Context';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import { useDispatch } from 'react-redux';
import { addStudent } from 'redux/features/student/actions';
import { useSelector } from 'react-redux';
import { fetchCampuses } from 'redux/features/campus/actions';
import { fetchFeeStructure } from 'redux/features/feeStructure/actions';
import { guardianFilter } from 'redux/features/guardians/actions';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const WizardLayout = ({ variant, validation, progressBar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get the campuses
  const campuses = useSelector(state => state.campuses.data);

  useEffect(() => {
    dispatch(fetchCampuses());
    dispatch(fetchFeeStructure());
    dispatch(guardianFilter());
  }, []);

  const { isRTL } = useContext(AppContext);
  const { user, setUser, step, setStep } = useContext(AddStudentWizardContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors
  } = useForm();

  const [modal, setModal] = useState(false);

  const navItems = [
    {
      icon: 'table',
      label: '1. Select a seat'
    },
    {
      icon: 'user',
      label: '2. Select guardian'
    },
    {
      icon: 'school',
      label: '3. Student Details'
    }
  ];

  const onSubmitData = data => {
    setUser({ ...user, ...data });
    setStep(step + 1);
    if (step === 3) {
      const formData = new FormData();

      formData.append('campus', data.campusName);
      formData.append('session', data.session);
      formData.append('grade', data.grade);
      formData.append(
        'date_of_enrolment',
        new Date(data.dateOfEnrollment).toISOString()
      );
      formData.append('guardian_cnic', data.guaridanID);
      formData.append('relation_with_guardian', data.relation_with_guardian);
      formData.append('photoPath', data.photoPath[0]);
      formData.append('fullname', data.studentName);
      formData.append('gender', data.gender);
      formData.append('fullname_urdu', data.nameInUrdu);
      formData.append('father_name', data.father_name);
      formData.append('father_cnic', data.father_cnic);
      formData.append('mother_name', data.mother_name);
      formData.append('dateof_birth', new Date(data.birthDate).toISOString());
      formData.append('domicile', data.local);

      dispatch(addStudent(formData))
        .unwrap()
        .then(() => {
          Swal.fire(
            'Successful',
            'Student has been added successfully',
            'success'
          );
          setTimeout(() => {
            navigate('/students');
          }, 1000);
        })
        .catch(err => {
          Swal.fire('Failed to add student!', err.message, 'error');
        });
    }
  };

  const onError = () => {
    if (!validation) {
      clearErrors();
      setStep(step + 1);
    }
  };

  const toggle = () => setModal(!modal);

  const handleNavs = targetStep => {
    if (step !== 3) {
      if (targetStep < step) {
        // setStep(targetStep);
      } else {
        handleSubmit(onSubmitData, onError)();
      }
    } else {
      toggle();
    }
  };

  return (
    <>
      <Card
        as={Form}
        noValidate
        onSubmit={handleSubmit(onSubmitData, onError)}
        className="theme-wizard mb-5"
      >
        <Card.Header
          className={classNames('bg-light', {
            'px-4 py-3': variant === 'pills',
            'pb-2': !variant
          })}
        >
          <Nav className="justify-content-center" variant={variant}>
            {variant === 'pills'
              ? navItems.map((item, index) => (
                  <NavItemPill
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))
              : navItems.map((item, index) => (
                  <NavItem
                    key={item.label}
                    index={index + 1}
                    step={step}
                    handleNavs={handleNavs}
                    icon={item.icon}
                    label={item.label}
                  />
                ))}
          </Nav>
        </Card.Header>
        {progressBar && <ProgressBar now={step * 25} style={{ height: 2 }} />}
        <Card.Body className="fw-normal px-md-6 py-4">
          {step === 1 && (
            <SeatForm
              register={register}
              errors={errors}
              setValue={setValue}
              data={campuses}
            />
          )}
          {step === 2 && (
            <GuardianForm
              register={register}
              errors={errors}
              setValue={setValue}
            />
          )}
          {step === 3 && (
            <StudentForm
              register={register}
              errors={errors}
              setValue={setValue}
            />
          )}
        </Card.Body>
        <Card.Footer
          className={classNames('px-md-6 bg-light', {
            'd-none': step === 4,
            ' d-flex': step < 4
          })}
        >
          {/* <IconButton
            variant="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semi-bold', {
              'd-none': step === 1
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
            Prev
          </IconButton> */}

          {step === 3 ? (
            <IconButton
              variant="success"
              type="submit"
              className="ms-auto px-5"
              icon={'check'}
              iconAlign="right"
              transform="down-1 shrink-4"
            >
              Save Student
            </IconButton>
          ) : (
            <IconButton
              variant="primary"
              type="submit"
              className="ms-auto px-5"
              icon={isRTL ? 'chevron-left' : 'chevron-right'}
              iconAlign="right"
              transform="down-1 shrink-4"
            >
              Next
            </IconButton>
          )}
        </Card.Footer>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 4 ? step > index : step > 3,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const NavItemPill = ({ index, step, handleNavs, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: step > index,
          active: step === index
        })}
        onClick={() => handleNavs(index)}
      >
        <Flex alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={icon} />
          <span className="d-none d-md-block mt-1 fs--1 ms-2">{label}</span>
        </Flex>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  variant: PropTypes.oneOf(['pills']),
  validation: PropTypes.bool,
  progressBar: PropTypes.bool
};

NavItemPill.propTypes = {
  index: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  handleNavs: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

NavItem.propTypes = NavItemPill.propTypes;

export default WizardLayout;
