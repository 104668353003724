import { createSlice } from '@reduxjs/toolkit';
import { STATUSES } from 'common/constants';
import { fetchFeeStructure, updateFeeStructure } from './actions';

export const feeStructureSlice = createSlice({
  name: 'feeStructure',
  initialState: {
    data: [],
    error: '',
    status: STATUSES.IDLE
  },
  extraReducers: builder => {
    builder
      // Fetch Fee Structure
      .addCase(fetchFeeStructure.rejected, (state, action) => {
        state.error = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchFeeStructure.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchFeeStructure.pending, (state, action) => {
        state.status = STATUSES.LOADING;
        state.error = action.payload;
      })

      // Update Fee Structure
      .addCase(updateFeeStructure.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = action.payload;
      })
      .addCase(updateFeeStructure.pending, (state, action) => {
        state.status = STATUSES.LOADING;
        state.error = action.payload;
      })
      .addCase(updateFeeStructure.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = state.data.map(obj => {
          const idx = action.payload.findIndex(e => e.id === obj.id);
          if (idx !== -1) return action.payload[idx];
          else return obj;
        });
      });
  }
});

export default feeStructureSlice.reducer;
