import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import CardHeader from 'components/common/CardHeader';
import IconButton from 'components/common/IconButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { gurdianTableColumns } from './partials/GurdianTableColumns';
import { Link } from 'react-router-dom';
import { fetchGuardians } from 'redux/features/guardians/actions';
import { STATUSES } from 'common/constants';
import Loader from 'components/common/Loader';

const ViewGuardians = () => {
  const dispatch = useDispatch();

  const data = useSelector(state => state.guardian.data, shallowEqual);
  const status = useSelector(state => state.guardian.status, shallowEqual);

  const [guardian, setGuardian] = useState(data);

  useEffect(() => {
    dispatch(fetchGuardians());
  }, []);

  useEffect(() => {
    setGuardian([...data].sort((a, b) => a.id - b.id));
  }, [data]);

  return status === STATUSES.LOADING ? (
    <Card>
      <Loader />
    </Card>
  ) : (
    <>
      <Card>
        <CardHeader
          title="Guardians"
          icon="street-view"
          titleTag="h4"
          className="bg-light"
          endEl={
            <Link to="/guardians/add">
              <IconButton
                variant="falcon-primary"
                size="sm"
                iconAlign="right"
                icon="plus"
                iconClassName="ms-1"
              >
                Add Guardian
              </IconButton>
            </Link>
          }
        />
        <Card.Body>
          <AdvanceTableWrapper
            columns={gurdianTableColumns}
            data={guardian}
            pagination
            perPage={10}
            extra={'extra'}
          >
            <Row className="flex-end-center mb-3">
              <Col xs="auto" sm={6} lg={4}>
                <AdvanceTableSearchBox table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={guardian.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </Card.Body>
      </Card>
    </>
  );
};

export default ViewGuardians;
