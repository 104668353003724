import React, { useState } from 'react';
import { Col, Form, Row, Image } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { relationWithGuardian } from './Enums';

const EditStudentInfoForm = ({
  studentForm,
  setStudentForm,
  singleStudent,
  handleChange
}) => {
  // Image preview
  const [previewImage, setPreviewImage] = useState(null);

  const handleFileChange = event => {
    const file = event.target.files;
    setStudentForm({
      ...studentForm,
      photoPath: file
    });
    if (file.length) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file[0]);
    } else {
      setPreviewImage(null);
    }
    console.log('FILE => ', studentForm);
  };

  return (
    <Col>
      <h5 className="mt-4">Student Info</h5>
      <Form.Group controlId="photoPath" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Image:</Form.Label>
          </Col>
          <Col xs={8}>
            {previewImage ? (
              <Image
                src={previewImage}
                alt="Preview"
                style={{ maxWidth: '200px' }}
                rounded
              />
            ) : (
              <Image
                src={studentForm.photoPath}
                style={{ maxWidth: '200px' }}
              />
            )}
            <Form.Control
              type="file"
              key={singleStudent.photoPath}
              name="photoPath"
              onChange={handleFileChange}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="studentId" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Student ID:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              key={singleStudent.student_id}
              value={singleStudent.student_id}
              disabled
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="fullname" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Name:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              key={singleStudent.fullname}
              name="fullname"
              value={studentForm.fullname}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="fullname_urdu" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Name in Urdu:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              key={singleStudent.fullname_urdu}
              name="fullname_urdu"
              value={studentForm.fullname_urdu}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="gender" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Gender:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Select
              key={singleStudent.gender}
              name="gender"
              value={studentForm.gender}
              onChange={handleChange}
            >
              <option value="Boy">Boy</option>
              <option value="Girl">Girl</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="date_of_brith" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Date of Birth:</Form.Label>
          </Col>
          <Col xs={8}>
            <ReactDatePicker
              onChange={e => {
                setStudentForm({
                  ...studentForm,
                  dateof_birth: new Date(e).toLocaleDateString()
                });
              }}
              className="form-control"
              placeholderText="Select Date"
              value={new Date(studentForm.dateof_birth).toLocaleDateString()}
              name="dateof_birth"
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="domicile" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Domicile:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              key={singleStudent.domicile}
              name="domicile"
              value={studentForm.domicile}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="father_name" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Father Name:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              key={singleStudent.father_name}
              name="father_name"
              value={studentForm.father_name}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="mother_name" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Mother Name:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              key={singleStudent.mother_name}
              name="mother_name"
              value={studentForm.mother_name}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="father_cnic" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Father CNIC:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Control
              type="text"
              key={singleStudent.father_cnic}
              name="father_cnic"
              value={studentForm.father_cnic}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="relation_with_guardian" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Relation with child:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Select
              key={singleStudent.relation_with_guardian}
              name="relation_with_guardian"
              value={studentForm.relation_with_guardian}
              onChange={handleChange}
            >
              {relationWithGuardian.map(relation => {
                return (
                  <option value={relation.name} key={relation.name}>
                    {relation.name}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>
    </Col>
  );
};

EditStudentInfoForm.propTypes = {
  studentForm: PropTypes.object,
  singleStudent: PropTypes.object,
  handleChange: PropTypes.func,
  setStudentForm: PropTypes.func
};

export default EditStudentInfoForm;
