import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';
import { useDispatch } from 'react-redux';
import { fetchAcademicLevels } from 'redux/features/common/actions';

const App = () => {
  let dispatch = useDispatch();

  // Common
  useEffect(() => {
    dispatch(fetchAcademicLevels());
  }, [dispatch]);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
  );
};

export default App;
