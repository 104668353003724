import React from 'react';
import PropTypes from 'prop-types';
import ControlledInput from 'common/Input/Input';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { boolean } from 'is_js';

export const CampusGrade = ({ idx, key, data, handleChange, IsEdit }) => {
  return (
    <tr key={key}>
      <td>{data.grade}</td>
      <td>{data.equivalent}</td>
      <td>
        {IsEdit ? (
          <ControlledInput
            type="number"
            value={data.allocatedSeats}
            name="allocatedSeats"
            onChange={handleChange}
            index={idx}
          />
        ) : (
          data.allocatedSeats
        )}
      </td>
      <td>
        {IsEdit ? (
          <ControlledInput
            type="checkbox"
            name="genderDestribution"
            onChange={handleChange}
            value={data.genderDestribution}
            index={idx}
          />
        ) : data.genderDestribution === true ? (
          <>
            <SoftBadge pill bg="success" className="fs--2">
              <FontAwesomeIcon
                icon="check"
                className="ms-1"
                transform="shrink-2"
              />
            </SoftBadge>
          </>
        ) : (
          <>
            <SoftBadge pill bg="warning" className="fs--2">
              <FontAwesomeIcon
                icon="times"
                className="ms-1"
                transform="shrink-2"
              />
            </SoftBadge>
          </>
        )}
      </td>
      <td>
        {IsEdit ? (
          <ControlledInput
            type="number"
            value={data.girlsCount}
            name="girlsCount"
            onChange={handleChange}
            index={idx}
            disabled={data.genderDestribution ? false : true}
            max={data.allocatedSeats - data.boysCount}
          />
        ) : (
          data.girlsCount
        )}
      </td>
      <td>
        {IsEdit ? (
          <ControlledInput
            type="number"
            value={data.boysCount}
            name="boysCount"
            onChange={handleChange}
            index={idx}
            disabled={data.genderDestribution ? false : true}
            max={data.allocatedSeats - data.girlsCount}
          />
        ) : (
          data.boysCount
        )}
      </td>
    </tr>
  );
};

CampusGrade.propTypes = {
  key: PropTypes.number,
  data: PropTypes.object,
  idx: PropTypes.number,
  handleChange: PropTypes.func,
  IsEdit: boolean
};
