import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { capitalize } from 'helpers/utils';
import { cardBorderShadowStyle } from 'common/commonStyles';
import avatar from '../../../../assets/img/images/avatar.png';

const StudentOtherInfo = ({ data }) => {
  return (
    <>
      <h5>Guardian Details</h5>
      <>
        <Link to={`/guardians/${data.id}`} style={{ textDecoration: 'none' }}>
          <Card className="mb-4" style={cardBorderShadowStyle}>
            <Card.Body>
              <Flex alignItems="center">
                <Avatar size="xl" src={avatar} className="me-2" />

                <div className="flex-1">
                  <h5 className="mb-0 fs--1">
                    <span>
                      {data.guardian.title} {data.guardian.name}
                    </span>{' '}
                    <br />
                    <small>{data.guardian.guardianId}</small> <br />
                    <br />
                  </h5>
                </div>
                <div>
                  <h5 className="mb-0 fs--1">
                    <strong>Relation with guardian</strong> <br />
                    {capitalize(data.relation_with_guardian)}
                  </h5>
                </div>
              </Flex>
            </Card.Body>
          </Card>
        </Link>

        <h5>Status</h5>
        <Card className="mb-4" style={cardBorderShadowStyle}>
          <Card.Body>
            <h5 className="mb-0  text-center text-success">
              {data.status === 'new_enrolment' ? 'New Enrollment' : ''}
            </h5>
          </Card.Body>
        </Card>

        <h5>Session</h5>
        <Card className="mb-3" style={cardBorderShadowStyle}>
          <Card.Body>
            <h5 className="mb-0 text-center">{data.session}</h5>
          </Card.Body>
        </Card>
      </>
    </>
  );
};

StudentOtherInfo.propTypes = {
  data: PropTypes.object
};

export default StudentOtherInfo;
