export const uniqueArrayOfObjects = (arr, identifier) => {
  if (!arr.length) return arr;
  if (!identifier || identifier === '' || identifier === undefined) {
    return arr;
  }
  if (typeof arr[0] === 'object' && arr[0] !== null && !Array.isArray(arr[0])) {
    let obj = {};

    for (let i = 0; i < arr.length; i++) {
      if (!obj[arr[i][identifier]]) {
        obj[arr[i][identifier]] = arr[i];
      }
    }
    return Object.values(obj);
  }

  return arr;
};
