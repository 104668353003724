import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import CardHeader from 'components/common/CardHeader';
import { CampusTableColumns } from './partials/CampusTableColumns';
import CreateCampusModal from './CreateCampusModal';
import { useDispatch } from 'react-redux';
import { fetchCampuses } from 'redux/features/campus/actions';
import { useSelector } from 'react-redux';
import Loading from '../../components/common/Loader';
import { STATUSES } from 'common/constants';
import UpdateCampusModal from './UpdateCampus';

const ViewCampuses = () => {
  const dispatch = useDispatch();

  let { data: campuses, status } = useSelector(state => state.campuses);
  const [campusData, setCampusData] = useState(campuses);

  useEffect(() => {
    dispatch(fetchCampuses());
  }, [dispatch]);

  useEffect(() => {
    setCampusData(campuses);
  }, [campuses]);

  return status === STATUSES.LOADING ? (
    <Card>
      <Loading />
    </Card>
  ) : (
    <Card>
      <CardHeader
        title="Campuses"
        icon="building"
        titleTag="h4"
        className="bg-light"
        endEl={<CreateCampusModal />}
      />
      <Card.Body>
        <AdvanceTableWrapper
          columns={CampusTableColumns}
          data={campusData}
          pagination
          perPage={10}
        >
          <Row className="flex-end-center mb-3">
            <Col xs="auto" sm={6} lg={4}>
              <AdvanceTableSearchBox table />
            </Col>
          </Row>
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              bordered: true,
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
          <UpdateCampusModal />
          <div className="mt-3">
            <AdvanceTableFooter
              rowCount={campusData.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

export default ViewCampuses;
