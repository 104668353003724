import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Table } from 'react-bootstrap';

const PersonalInfo = ({ data }) => {
  return (
    <>
      <h5>Personal Info</h5>
      <Table borderless size="sm">
        <tbody>
          <tr>
            <th>Full Name</th>
            <td>
              {data.title} {data.name}
            </td>
          </tr>
          <tr>
            <th>Guardian ID</th>
            <td>{data.guardianId}</td>
          </tr>
          <tr>
            <th>CNIC</th>
            <td>{data.cnic}</td>
          </tr>
          <tr>
            <th>Postal Address</th>
            <td>{data.postalAddress}</td>
          </tr>
          <tr>
            <th>Status</th>
            <td>
              {data.suspend ? (
                <Badge bg="warning">Suspended</Badge>
              ) : (
                <Badge>Active</Badge>
              )}
            </td>
          </tr>
        </tbody>
      </Table>

      <h5 className="mt-3">Contact Info</h5>
      <Table borderless size="sm">
        <tbody>
          <tr>
            <th>Email</th>
            <td>{data.email}</td>
          </tr>
          <tr>
            <th>Cell Number (Primary)</th>
            <td>{data.primaryNumber}</td>
          </tr>
          <tr>
            <th>Cell Number (Alternate)</th>
            <td>{data.secondaryNumber}</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

PersonalInfo.propTypes = {
  data: PropTypes.object
};

export default PersonalInfo;
