export const relationWithGuardian = [
  {
    id: 'Father',
    name: 'Father'
  },
  {
    id: 'Mother',
    name: 'Mother'
  },
  {
    id: 'Uncle',
    name: 'Uncle'
  },
  {
    id: 'Aunt',
    name: 'Aunt'
  },
  {
    id: 'Brother',
    name: 'Brother'
  },
  {
    id: 'Sister',
    name: 'Sister'
  },
  {
    id: 'Relative',
    name: 'Relative'
  },
  {
    id: 'Other',
    name: 'Other'
  }
];
