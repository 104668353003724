import { createSlice } from '@reduxjs/toolkit';
import { STATUSES } from 'common/constants';
import {
  fetchCampuses,
  createCampus,
  showCampus,
  deleteCampus,
  updateCampus,
  updateGrade,
  updateSignature
} from './actions';

// Slices
export const campusSlice = createSlice({
  name: 'campuses',
  initialState: {
    data: [],
    error: null,
    status: STATUSES.IDLE,
    singleCampus: {},
    showModel: false,
    edit: false
  },
  extraReducers(builder) {
    builder
      // Fetch Campuses
      .addCase(fetchCampuses.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;
        state.data = action.payload.sort((a, b) => a.id - b.id);
        state.error = null;
      })
      .addCase(fetchCampuses.pending, state => {
        state.status = STATUSES.LOADING;
        state.error = null;
      })
      .addCase(fetchCampuses.rejected, (state, actions) => {
        state.status = STATUSES.IDLE;
        state.error = actions.payload;
      })

      // Create Campus
      .addCase(createCampus.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.status = STATUSES.IDLE;
        state.error = null;
      })
      .addCase(createCampus.rejected, (state, action) => {
        state.error = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(createCampus.pending, state => {
        state.status = STATUSES.LOADING;
        state.error = null;
      })

      // Create Campus
      .addCase(showCampus.fulfilled, (state, action) => {
        state.singleCampus = action.payload;
        state.status = STATUSES.IDLE;
        state.error = null;
      })
      .addCase(showCampus.pending, state => {
        state.status = STATUSES.LOADING;
        state.error = null;
      })
      .addCase(showCampus.rejected, (state, action) => {
        state.error = action.payload;
        state.status = STATUSES.IDLE;
      })

      // Delete Campus
      .addCase(deleteCampus.fulfilled, (state, action) => {
        state.data = state.data.filter(item => item.id !== action.payload.id);
        state.status = STATUSES.IDLE;
      })
      .addCase(deleteCampus.rejected, (state, action) => {
        state.state = STATUSES.IDLE;
        state.error = action.payload;
      })
      .addCase(deleteCampus.pending, state => {
        state.status = STATUSES.pending;
      })

      // Update Campus
      .addCase(updateCampus.fulfilled, (state, action) => {
        state.data = state.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        });
        state.status = STATUSES.IDLE;
      })
      .addCase(updateCampus.rejected, (state, action) => {
        state.state = STATUSES.IDLE;
        state.error = action.payload;
      })
      .addCase(updateCampus.pending, state => {
        state.status = STATUSES.pending;
      })

      // Update Grade
      .addCase(updateGrade.fulfilled, state => {
        state.status = STATUSES.IDLE;
        state.error = null;
      })
      .addCase(updateGrade.pending, state => {
        state.status = STATUSES.LOADING;
        state.error = null;
      })
      .addCase(updateGrade.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = action.payload;
      })

      // Update Signature
      .addCase(updateSignature.fulfilled, (state, action) => {
        state.status = STATUSES.IDLE;

        state.data = state.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        });
      })
      .addCase(updateSignature.rejected, (state, action) => {
        state.status = STATUSES.IDLE;
        state.error = action.payload;
      })
      .addCase(updateSignature.pending, state => {
        state.status = STATUSES.LOADING;
        state.error = null;
      })

      // Campus modal
      .addCase('show/model/create', state => {
        state.showModel = true;
        state.edit = false;
        state.singleCampus = {};
      })
      .addCase('show/model', (state, action) => {
        state.showModel = true;
        state.edit = true;
        state.singleCampus = state.data.find(e => e.id == action.payload);
      })
      .addCase('hide/model', state => {
        state.showModel = false;
        state.edit = false;
        state.singleCampus = {};
      });
  }
});

export default campusSlice.reducer;
