import { createSlice } from '@reduxjs/toolkit';
import { STATUSES } from 'common/constants';
import {
  addStudent,
  fetchStudents,
  deleteStudent,
  updateStudent,
  filterStudents,
  showStudent
} from './actions';

export const studentsSlice = createSlice({
  name: 'students',
  initialState: {
    data: [],
    errors: [],
    status: STATUSES.LOADING,
    singleStudent: null
  },
  extraReducers(builder) {
    builder;
    // Add Student
    builder.addCase(addStudent.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload];
      state.status = STATUSES.IDLE;
      state.errors = [];
    });
    builder.addCase(addStudent.rejected, (state, action) => {
      state.status = STATUSES.ERROR;
      state.errors = action.payload.message;
    });
    builder.addCase(addStudent.pending, state => {
      state.status = STATUSES.LOADING;
      state.errors = [];
    });

    // Filter Student
    builder.addCase(filterStudents.fulfilled, state => {
      state.status = STATUSES.IDLE;
    });
    builder.addCase(filterStudents.pending, state => {
      state.status = STATUSES.LOADING;
    });
    builder.addCase(filterStudents.rejected, (state, action) => {
      state.status = STATUSES.IDLE;
      state.error = action.payload;
    });

    // Fetch Student
    builder.addCase(fetchStudents.fulfilled, (state, action) => {
      console.log('Action => ', action.payload);
      state.data = action.payload.results;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(fetchStudents.pending, state => {
      state.status = STATUSES.LOADING;
    });
    builder.addCase(fetchStudents.rejected, (state, action) => {
      state.status = STATUSES.IDLE;
      state.error = action.payload;
    });

    // Delete Student
    builder.addCase(deleteStudent.fulfilled, (state, action) => {
      state.status = STATUSES.IDLE;
      state.data = state.data.filter(data => data.id !== action.payload);
    });
    builder.addCase(deleteStudent.pending, () => {});
    builder.addCase(deleteStudent.rejected, (state, action) => {
      state.status = STATUSES.IDLE;
      state.error = action.payload;
    });

    // Update Student
    builder.addCase(updateStudent.fulfilled, (state, action) => {
      state.status = STATUSES.IDLE;
      state.data = state.data.map(el =>
        el.id === action.payload.id ? action.payload : el
      );
    });
    builder.addCase(updateStudent.pending, state => {
      state.status = STATUSES.LOADING;
    });
    builder.addCase(updateStudent.rejected, (state, action) => {
      state.status = STATUSES.IDLE;
      state.error = action.payload;
    });

    // Show Student
    builder.addCase(showStudent.fulfilled, (state, action) => {
      console.log('Action playload ', action.payload);
      state.singleStudent = action.payload;
      state.status = STATUSES.IDLE;
    });
    builder.addCase(showStudent.pending, state => {
      state.status = STATUSES.LOADING;
    });
    builder.addCase(showStudent.rejected, (state, action) => {
      state.status = STATUSES.IDLE;
      state.error = action.payload;
    });
  }
});

export default studentsSlice.reducer;
