import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Student from './Student';
import Employee from './Employee';
import Campus from './Campus';
import Weather from './Weather';
import StudentFee from './StudentFee';
import Inventory from './Inventory';
import Meeting from './Meeting';

const students = {
  title: 'Total Students',
  studentCount: '2323'
};

const employees = {
  title: 'Employees',
  employeeCount: 32
};

const campus = {
  title: 'Campus',
  campusCount: 2
};

export const weather = {
  city: 'Quetta Balochistan',
  condition: 'Sunny',
  precipitation: Math.floor(Math.random() * 20) + '%',
  temperature: Math.floor(Math.random() * 20),
  highestTemperature: Math.floor(Math.random() * 20),
  lowestTemperature: Math.floor(Math.random() * 20)
};

export const studentFee = [
  { id: 1, value: 32423, name: 'Paid Fee', color: 'primary' },
  { id: 2, value: 2323, name: 'Pending Fee', color: 'info' },
  { id: 3, value: 3233, name: 'Scholarship', color: 'gray-300' }
];

const inventory = [
  {
    id: 1,
    title: 'Pencils',
    progress: 90,
    quantity: 322,
    color: 'primary'
  },
  {
    id: 2,
    title: 'Erasers',
    progress: 50,
    quantity: 221,
    color: 'success'
  },
  {
    id: 3,
    title: 'Pointers',
    progress: 79,
    quantity: 123,
    color: 'info'
  },
  {
    id: 4,
    title: 'Sharners',
    progress: 38,
    quantity: 23,
    color: 'warning'
  }
];

const Dashboard = () => {
  return (
    <>
      <Row>
        <Col>
          <h4 className="pb-2">
            Welcome back, <strong>Imdad</strong>
          </h4>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={4} xxl={3}>
          <Student data={students} />
        </Col>
        <Col md={4} xxl={3}>
          <Employee data={employees} />
        </Col>
        <Col>
          <Campus data={campus} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6}>
          <Weather data={weather} />
        </Col>
        <Col lg={6}>
          <StudentFee data={studentFee} radius={['100%', '85%']} />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={6} xl={7} xxl={8}>
          <Inventory data={inventory} />
        </Col>
        <Col lg={6} xl={5} xxl={4}>
          <Meeting />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={7} xl={8}></Col>
        <Col lg={5} xl={4}></Col>
      </Row>

      <Row className="g-3">
        <Col sm={6} xxl={3} className="order-xxl-1"></Col>
        <Col xxl={6}></Col>
      </Row>
    </>
  );
};

export default Dashboard;
