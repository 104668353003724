import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { deleteCampus } from 'redux/features/campus/actions';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';

const ActionController = ({ id }) => {
  const dispatch = useDispatch();

  // Delete Campus
  const handleDelete = id => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(deleteCampus(id));
        Swal.fire('Deleted!', 'Campus has been deleted', 'success');
      }
    });
  };

  // Edit Campus
  const hanldeEdit = id => {
    dispatch({ type: 'show/model', payload: id });
  };

  return (
    <div className=" end-0 top-50 pe-3">
      <Button
        variant="light"
        size="sm"
        className="border-300 me-1 text-600"
        onClick={() => hanldeEdit(id)}
        disabled
      >
        <FontAwesomeIcon icon="edit" />
      </Button>
      <Button
        variant="light"
        size="sm"
        className="border-300 text-600"
        onClick={() => handleDelete(id)}
      >
        <FontAwesomeIcon icon="trash-alt" />
      </Button>
    </div>
  );
};

ActionController.propTypes = {
  id: PropTypes.number
};

export const CampusTableColumns = [
  {
    accessor: 'name',
    Header: 'Name',
    headerProps: { className: 'pe-1' },
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { name, id } = rowData.row.original;
      return (
        <Link to={`/campuses/${id}`}>
          <Flex alignItems="center">
            <Avatar size="xl" name={name} className="me-2" />
            <div className="flex-1">
              <h5 className="mb-0 fs--1">{name}</h5>
            </div>
          </Flex>
        </Link>
      );
    }
  },
  {
    accessor: 'campusId',
    Header: 'Campus ID'
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: rowData => {
      const { status } = rowData.row.original;
      return status === 'active' ? (
        <Badge bg="success">Active</Badge>
      ) : (
        <Badge bg="warning">Inactive</Badge>
      );
    }
  },
  {
    accessor: 'none',
    Header: 'Actions',
    disableSortBy: true,
    cellProps: {
      className: 'text-end py-2'
    },
    Cell: rowData => <ActionController id={rowData.row.original.id} />
  }
];
