import React, { useEffect, useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { fetchCampuses } from 'redux/features/campus/actions';
import { uniqueArrayOfObjects } from 'utils/unique';
import { useDispatch } from 'react-redux';
import { fetchFeeStructure } from 'redux/features/feeStructure/actions';

const EditEnrollmentForm = ({
  studentForm,
  setStudentForm,
  singleStudent,
  handleChange
}) => {
  const dispatch = useDispatch();

  const { data: campuses } = useSelector(state => state.campuses);

  const feeStructure = useSelector(state => state.feeStructure);
  const [campusGrade, setCampusGrade] = useState([]);

  const handleOnCampus = e => {
    const selectedIndex = e.target.options.selectedIndex;
    const id = parseInt(
      e.target.options[selectedIndex].getAttribute('data-key')
    );
    const campus = campuses.find(e => parseInt(e.id) === id);
    let oldStudent = { ...studentForm };
    oldStudent.campus = campus;
    setStudentForm(oldStudent);
  };

  const handleOnGradeChange = e => {
    const selectedIndex = e.target.options.selectedIndex;
    const id = parseInt(
      e.target.options[selectedIndex].getAttribute('data-key')
    );
    for (let i = 0; i < campuses.length; i++) {
      const index = campuses[i].seats.findIndex(e => e.id === id);
      if (index !== -1) {
        const oldStudent = { ...studentForm };
        const grade = campuses[i].seats[index];
        oldStudent.grade = grade;
        setStudentForm(oldStudent);
        break;
      }
    }
  };

  useEffect(() => {
    dispatch(fetchCampuses());
    dispatch(fetchFeeStructure());
  }, []);

  useEffect(() => {
    if (Object.keys(studentForm).length) {
      let seats = campuses.filter(e => e.id === studentForm.campus.id);
      if (seats.length >= 1) {
        setCampusGrade(
          seats[0].seats.map(e => {
            return { id: e.id, name: e.equivalent };
          })
        );
      }
    }
  }, [studentForm, campuses]);

  let sessionYears = uniqueArrayOfObjects(feeStructure.data, 'year').map(
    session => {
      return {
        id: session.year,
        name: session.year
      };
    }
  );

  return (
    <Col>
      <h5>Enrollment Info</h5>
      <Form.Group controlId="campus" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Campus :</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Select
              key={singleStudent.campus.id}
              name="campus"
              value={studentForm.campus.name}
              onChange={handleOnCampus}
            >
              {campuses.map(campus => {
                return (
                  <option
                    // value={campus.name}
                    key={campus.id}
                    data-key={campus.id}
                  >
                    {campus.name}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="session" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Enrollment Session:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Select
              key={singleStudent.session}
              name="session"
              value={studentForm.session}
              onChange={handleChange}
            >
              {sessionYears.map(year => {
                return (
                  <option value={year.name} key={year.id}>
                    {year.name}
                  </option>
                );
              })}
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="grade" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Enrollment Grade:</Form.Label>
          </Col>
          <Col xs={8}>
            <Form.Select
              key={singleStudent.grade.id}
              name="grade"
              value={studentForm.grade.equivalent}
              onChange={handleOnGradeChange}
            >
              {campusGrade.map(grade => (
                <option key={grade.id} data-key={grade.id}>
                  {grade.name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group controlId="date_of_enrollment" className="mb-2">
        <Row>
          <Col xs={4} className="d-flex justify-content-end pt-1">
            <Form.Label>Date Of Enrollment:</Form.Label>
          </Col>
          <Col xs={8}>
            <ReactDatePicker
              onChange={e => {
                setStudentForm({
                  ...studentForm,
                  date_of_enrolment: new Date(e).toLocaleDateString()
                });
              }}
              className="form-control"
              placeholderText="Select Date"
              value={new Date(
                studentForm.date_of_enrolment
              ).toLocaleDateString()}
              name="date_of_enrolment"
            />
          </Col>
        </Row>
      </Form.Group>
    </Col>
  );
};

EditEnrollmentForm.propTypes = {
  studentForm: PropTypes.any,
  singleStudent: PropTypes.object,
  handleChange: PropTypes.func,
  setStudentForm: PropTypes.func
};

export default EditEnrollmentForm;
