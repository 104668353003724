import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/CardHeader';
import EmployeeIcon from 'assets/img/icons/users.svg';
import Flex from 'components/common/Flex';

const Employee = ({ data: { title, employeeCount } }) => (
  <Card>
    <FalconCardHeader
      title="Employees"
      light={false}
      titleTag="h5"
      className="pb-3"
    ></FalconCardHeader>
    <Card.Body>
      <Row className="g-0 h-100 align-items-center">
        <Col as={Flex} alignItems="center">
          <img className="me-3" src={EmployeeIcon} alt="" height="60" />
          <div>
            <h6 className="mb-2">{title}</h6>
            <div className="fs--2 fw-semi-bold"></div>
          </div>
        </Col>
        <Col xs="auto" className="text-center ps-2">
          <div className="fs-4 fw-normal font-sans-serif text-primary mb-1">
            {employeeCount}
          </div>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

Employee.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default Employee;
