import React, { useState } from 'react';
import { Modal, Button, CloseButton, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { createCampus } from 'redux/features/campus/actions';
import { useSelector } from 'react-redux';

const CreateCampusModal = () => {
  const dispatch = useDispatch();
  const { academicLevels } = useSelector(state => state.common);
  // States
  const [show, setShow] = useState(false);

  // Academic Levels & Status values
  const statusData = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' }
  ];

  // Form data
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm();

  const onSubmit = data => {
    const newData = {
      ...data,
      academicLevels: data.academicLevels.map(level => level.id),
      status: data.status.value
    };

    // API call
    dispatch(createCampus(newData))
      .unwrap()
      .then(() => {
        toast.success('Campus has been added');
        reset();
        setShow(false);
      })
      .catch(e => {
        toast.error(e.message);
      });
  };

  return (
    <>
      <IconButton
        variant="falcon-primary"
        size="sm"
        iconAlign="right"
        icon="plus"
        iconClassName="ms-1"
        onClick={() => setShow(true)}
      >
        Add Campus
      </IconButton>
      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Create New Campus</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShow(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gx-2 gy-3">
              <Col md={12}>
                <Form.Group controlId="name">
                  <Form.Label>Campus Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g Seemak Campus"
                    {...register('name', {
                      required: 'Campus name is required'
                    })}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name && errors.name.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="campusId">
                  <Form.Label>Unique ID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="e.g AA1"
                    isInvalid={!!errors.campusId}
                    {...register('campusId', {
                      required: 'Unique is required'
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.campusId && errors.campusId.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    placeholder="e.g Block C1"
                    isInvalid={!!errors.address}
                    {...register('address', {
                      required: 'Address is required'
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.address && errors.address.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="status">
                  <Form.Label>Status</Form.Label>
                  <Controller
                    name="status"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={statusData}
                        placeholder="Select..."
                        classNamePrefix="react-select"
                        isInvalid={!!errors.status}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.status?.stat || 'Status is required'}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group controlId="academic_levels">
                  <Form.Label>Academic Levels</Form.Label>
                  <Controller
                    name="academicLevels"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={academicLevels.map(el => {
                          return {
                            id: el.id,
                            label: el.name,
                            value: el.name
                          };
                        })}
                        placeholder="Select..."
                        classNamePrefix="react-select"
                        isMulti
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Button className="mt-2" type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateCampusModal;
