import React from 'react';
import { Card, Col, Row, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import avatar from '../../../../assets/img/images/avatar.png';

const ProfileCard = ({ data }) => {
  console.log('Student Dat', data);
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="g-3 align-items-center">
          <Col md={6} lg={12} className="text-center">
            {data.photoPath ? (
              <a href={data.photoPath} target="blank">
                <Image
                  src={data.photoPath}
                  alt="guardian image"
                  style={{ maxWidth: '200px' }}
                  thumbnail
                />
              </a>
            ) : (
              <Image
                src={avatar}
                alt="guardian image"
                style={{ maxWidth: '100px' }}
              />
            )}
          </Col>
          <Col md={6} lg={12}>
            <Row className="row-cols-1 g-0">
              <Col className="text-center">
                <h4>
                  {data.fullname} ({data.fullname_urdu})
                </h4>
                <h5 className="mb-1 text-800 fs-0">
                  {data.student_id} | {data.grade.equivalent}
                </h5>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ProfileCard.propTypes = {
  data: PropTypes.object
};

export default ProfileCard;
