export const columnLeftStyle = {
  width: '200px',
  fontWeight: 'bold',
  textAlign: 'right'
};

export const columnRightStyle = {
  paddingLeft: '40px'
};

export const cardBorderShadowStyle = {
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  border: '1px solid #DDD'
};
