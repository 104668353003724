import { createSlice } from '@reduxjs/toolkit';
import { STATUSES } from 'common/constants';
import { fetchEmployees } from './actions';

export const employeesSlice = createSlice({
  name: 'employees',
  initialState: {
    data: [],
    status: STATUSES.IDLE
  },
  extraReducers: builder => {
    // Fetch employees
    builder
      .addCase(fetchEmployees.pending, state => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.IDLE;
      })
      .addCase(fetchEmployees.rejected, state => {
        state.status = STATUSES.ERROR;
      });
  }
});

export const { setEmployees, setStatus } = employeesSlice.actions;
export default employeesSlice.reducer;
