import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/common/Header';
import { Card, Row, Col, Button, Image } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateBasicInfo } from 'redux/features/basicInfo/actions';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const EditBasicInfo = ({ setEdit }) => {
  // Redux Call
  const dispatch = useDispatch();
  const basicInfo = useSelector(state => state.basicInfo);

  // Image Change
  const [images, setImages] = useState({
    logo: basicInfo.data.logo_img ? basicInfo.data.logo_img : null,
    ceo: basicInfo.data.ceo_signature_img
      ? basicInfo.data.ceo_signature_img
      : null,
    eao: basicInfo.data.eao_signature_img
      ? basicInfo.data.eao_signature_img
      : null,
    epd: basicInfo.data.epd_signature_img
      ? basicInfo.data.epd_signature_img
      : null,
    edsa: basicInfo.data.edsa_signature_img
      ? basicInfo.data.edsa_signature_img
      : null
  });

  const handleImageChange = (event, inputName) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImages({ ...images, [inputName]: reader.result });
    };
  };

  // Submit Form
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async data => {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('logo_img', data.logo_img[0]);
    formData.append('ceo_signature_img', data.ceo_signature_img[0]);
    formData.append('eao_signature_img', data.eao_signature_img[0]);
    formData.append('edsa_signature_img', data.edsa_signature_img[0]);
    formData.append('epd_signature_img', data.epd_signature_img[0]);

    dispatch(
      updateBasicInfo({
        id: basicInfo.data.id ? basicInfo.data.id : 1,
        data: formData
      })
    )
      .unwrap()
      .then(() => {
        setEdit(false);
        toast.success('Basic Info has been updated');
      })
      .catch(e => {
        toast.error(e.message);
      });
  };

  return (
    <div>
      <Header
        title="Basic Info"
        icon="info"
        btnLink="/"
        btnTitle="Go Back"
      ></Header>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body className="bg-light">
            <Row className="gx-2 gy-3">
              <Col md="6">
                <Form.Group controlId="name">
                  <Form.Label>* Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="e.g name"
                    defaultValue={basicInfo.data.name}
                    {...register('name', { required: true })}
                  />
                  {errors.name && <p>Please provide a name.</p>}
                </Form.Group>
              </Col>
              <Col md="6">
                <Form.Group controlId="logo">
                  <Form.Label>Logo</Form.Label>
                  <Form.Control
                    type="file"
                    {...register('logo_img')}
                    onChange={event => handleImageChange(event, 'logo')}
                  />
                  {images.logo && (
                    <Image
                      src={images.logo}
                      alt="selected image"
                      thumbnail
                      rounded
                      className="mt-2"
                      width="50%"
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md="12">
                <h5 className="mt-3">Signatures</h5>
                <span className="text-muted text-xs">
                  Supported files: JPEG | PNG
                </span>
              </Col>
              <Col md={'6'}>
                <Form.Group>
                  <Form.Label>CEO</Form.Label>
                  <Form.Control
                    type="file"
                    {...register('ceo_signature_img')}
                    onChange={event => handleImageChange(event, 'ceo')}
                  />
                  {images.ceo && (
                    <Image
                      src={images.ceo}
                      alt="selected image"
                      thumbnail
                      className="mt-2"
                      width="50%"
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md={'6'}>
                <Form.Group>
                  <Form.Label>Executive Program Director</Form.Label>
                  <Form.Control
                    type="file"
                    name="ExecutiveProgramDirector"
                    {...register('epd_signature_img')}
                    onChange={event => handleImageChange(event, 'epd')}
                  />
                  {images.epd && (
                    <Image
                      src={images.epd}
                      alt="selected image"
                      thumbnail
                      className="mt-2"
                      width="50%"
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md={'6'}>
                <Form.Group>
                  <Form.Label>Exective Admin officer</Form.Label>
                  <Form.Control
                    type="file"
                    name="ExecutiveAdminOfficer"
                    {...register('eao_signature_img')}
                    onChange={event => handleImageChange(event, 'eao')}
                  />
                  {images.eao && (
                    <Image
                      src={images.eao}
                      alt="selected image"
                      thumbnail
                      className="mt-2"
                      width="50%"
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md={'6'}>
                <Form.Group>
                  <Form.Label>
                    Executive Director for Student Assessment
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="ExecutiveDirectorStudentAssessment"
                    {...register('edsa_signature_img')}
                    onChange={event => handleImageChange(event, 'edsa')}
                  />
                  {images.edsa && (
                    <Image
                      src={images.edsa}
                      alt="selected image"
                      thumbnail
                      className="mt-2"
                      width="50%"
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md="12" className="mt-4">
                <Button type="submit" style={{ marginRight: '10px' }}>
                  Save
                </Button>
                <Button onClick={() => setEdit(false)} variant="danger">
                  Cancel
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Form>
      </Card>
    </div>
  );
};

EditBasicInfo.propTypes = {
  setEdit: PropTypes.func
};

export default EditBasicInfo;
