import React from 'react';
import ControlledInput from './Input/Input';
import PropTypes from 'prop-types';
import { boolean } from 'is_js';

export const FeeTable = ({ data, idx, handleChange, IsEdit }) => {
  return (
    <>
      <tr key={idx}>
        <td>{data.level}</td>
        <td align="right">
          {IsEdit ? (
            <ControlledInput
              value={data.tuition_fee}
              name="tuition_fee"
              onChange={handleChange}
              index={idx}
              disabled="true"
            />
          ) : (
            data.tuition_fee.toLocaleString()
          )}
        </td>
        <td align="right">
          {IsEdit ? (
            <ControlledInput
              value={data.academic_fee}
              name="academic_fee"
              onChange={handleChange}
              index={idx}
            />
          ) : (
            data.academic_fee.toLocaleString()
          )}
        </td>
        <td align="right">
          {IsEdit ? (
            <ControlledInput
              value={data.admission_fee}
              name="admission_fee"
              onChange={handleChange}
              index={idx}
            />
          ) : (
            data.admission_fee.toLocaleString()
          )}
        </td>
        <td align="right">
          {IsEdit ? (
            <ControlledInput
              value={data.security_deposit}
              name="security_deposit"
              onChange={handleChange}
              index={idx}
            />
          ) : (
            data.security_deposit.toLocaleString()
          )}
        </td>
      </tr>
    </>
  );
};

FeeTable.propTypes = {
  data: PropTypes.object,
  idx: PropTypes.number,
  handleChange: PropTypes.func,
  IsEdit: boolean
};
