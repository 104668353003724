import Header from 'components/common/Header';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { shallowEqual, useDispatch } from 'react-redux';
import { showGuardian, updateGurdian } from 'redux/features/guardians/actions';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { STATUSES } from 'common/constants';
import Loader from 'components/common/Loader';
import { useNavigate } from 'react-router-dom';

const EditGuardian = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const data = useSelector(
    state => state.guardian.singleGuardian,
    shallowEqual
  );
  const status = useSelector(state => state.guardian.status, shallowEqual);

  const [guardianForm, setGuardianForm] = useState(data);

  useEffect(() => {
    dispatch(showGuardian(id));
  }, [dispatch]);

  useEffect(() => {
    setGuardianForm(data);
  }, [data]);

  // Handle Form
  const handleChange = e => {
    console.log(e.target.value);
    setGuardianForm({ ...guardianForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    const formData = new FormData();

    // Fields
    formData.append('title', guardianForm.title);
    formData.append('postalAddress', guardianForm.postalAddress);
    formData.append('primaryNumber', guardianForm.primaryNumber);
    formData.append('secondaryNumber', guardianForm.secondaryNumber);
    formData.append('email', guardianForm.email);

    // Dispatch
    dispatch(updateGurdian({ id, formData }))
      .unwrap()
      .then(() => {
        toast.success('Guardian has been updated');
        navigate('/guardians');
      })
      .catch(e => {
        toast.error(`Failed to update guardian ${e.error}`);
      });
  };

  return status === STATUSES.LOADING ? (
    <Card>
      <Loader />
    </Card>
  ) : (
    <div>
      <Header
        title="Update Guardian"
        icon="plus"
        btnLink="/"
        btnTitle="Go Back"
      ></Header>
      <Card className="mb-3">
        <Card.Body className="bg-light">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="CNIC" className="mb-2">
              <Row>
                <Col xs={3} className="d-flex justify-content-end pt-1">
                  <Form.Label>CNIC Number:</Form.Label>
                </Col>
                <Col xs={4}>
                  <Form.Control
                    type="text"
                    placeholder="e.g 00000-0000000-0"
                    key={data.cnic}
                    name="cnic"
                    value={guardianForm.cnic}
                    onChange={handleChange}
                    disabled
                  />
                </Col>
              </Row>
            </Form.Group>
            <>
              <Form.Group controlId="title" className="mb-2">
                <Row>
                  <Col xs={3} className="d-flex justify-content-end pt-1">
                    <Form.Label>Title:</Form.Label>
                  </Col>
                  <Col xs={4}>
                    <Form.Select
                      key={data.title}
                      name="title"
                      value={guardianForm.title}
                      onChange={handleChange}
                    >
                      <option value="Mr">Mr. </option>
                      <option value="Miss">Miss. </option>
                      <option value="Mrs">Mrs. </option>
                      <option value="Ms">Ms. </option>
                      <option value="Dr">Dr. </option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group controlId="name" className="mb-2">
                <Row>
                  <Col xs={3} className="d-flex justify-content-end pt-1">
                    <Form.Label>Name:</Form.Label>
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                      type="text"
                      key={data.name}
                      name="name"
                      value={guardianForm.name}
                      onChange={handleChange}
                      disabled
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group controlId="guardianID" className="mb-2">
                <Row>
                  <Col xs={3} className="d-flex justify-content-end pt-1">
                    <Form.Label>Guardian ID:</Form.Label>
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                      type="text"
                      key={data.guardianId}
                      value={guardianForm.guardianId}
                      onChange={handleChange}
                      disabled
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="postalAddress" className="mb-2">
                <Row>
                  <Col xs={3} className="d-flex justify-content-end pt-1">
                    <Form.Label>Postal Address:</Form.Label>
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                      type="text"
                      key={data.postalAddress}
                      name="postalAddress"
                      onChange={handleChange}
                      value={guardianForm.postalAddress}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="number" className="mb-2">
                <Row>
                  <Col xs={3} className="d-flex justify-content-end pt-1">
                    <Form.Label>Number (Primary):</Form.Label>
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                      type="text"
                      key={data.primaryNumber}
                      name="primaryNumber"
                      onChange={handleChange}
                      value={guardianForm.primaryNumber}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="secondaryNumber" className="mb-2">
                <Row>
                  <Col xs={3} className="d-flex justify-content-end pt-1">
                    <Form.Label>Cell Number:</Form.Label>
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                      type="text"
                      key={data.secondaryNumber}
                      name="secondaryNumber"
                      onChange={handleChange}
                      value={guardianForm.secondaryNumber}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="email" className="mb-2">
                <Row>
                  <Col xs={3} className="d-flex justify-content-end pt-1">
                    <Form.Label>Email:</Form.Label>
                  </Col>
                  <Col xs={4}>
                    <Form.Control
                      type="email"
                      key={data.email}
                      name="email"
                      onChange={handleChange}
                      value={guardianForm.email}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row className="mt-3">
                <Col xs={3}></Col>
                <Col xs={4}>
                  <Button variant="success" type="submit">
                    Update
                  </Button>
                </Col>
              </Row>
            </>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EditGuardian;
